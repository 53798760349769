// Mixins
// --------------------------------------------------
//
/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}


// Utilities
//@import "mixins/hide-text.less";
//@import "mixins/opacity.less";
//@import "mixins/image.less";
//@import "mixins/labels.less";
//@import "mixins/reset-filter.less";
//@import "mixins/resize.less";
//@import "mixins/responsive-visibility.less";
//@import "mixins/size.less";
//@import "mixins/tab-focus.less";
//@import "mixins/text-emphasis.less";
//@import "mixins/text-overflow.less";
//@import "mixins/vendor-prefixes.less";
//@import "mixins/background-clip.less";
//@import "mixins/sort.less";
//
//// Components
//@import "mixins/alerts.less";
//@import "mixins/buttons.less";
//@import "mixins/select.less";
//@import "mixins/panels.less";
//@import "mixins/pagination.less";
//@import "mixins/nav-divider.less";
//@import "mixins/forms.less";
//@import "mixins/table-row.less";
//@import "mixins/spinners.less";
//@import "mixins/switches.less";
//@import "mixins/iconbars.less";
//@import "mixins/dialogs.less";
//
//// Skins
//@import "mixins/background-variant.less";
//@import "mixins/border-radius.less";
//@import "mixins/gradients.less";
//
//// Layout
//@import "mixins/clearfix.less";
//@import "mixins/center-block.less";
//@import "mixins/navbar-vertical-align.less";
//@import "mixins/grid.less";