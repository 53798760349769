//
// Navbars
// --------------------------------------------------

// Wrapper and base class
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.navbar {
  font-size: floor(($component-font-size-base * 1.067)); // ~16px
  min-height: $navbar-height-base;
  margin-bottom: $navbar-margin-bottom;
  border: none;
  border-radius: $navbar-border-radius;
}

// Navbar heading
//
// Groups `.navbar-brand` and `.navbar-toggle` into a single component for easy
// styling of responsive aspects.

.navbar-header {
  @media (min-width: $grid-float-breakpoint) {
    float: left;
  }
}

// Navbar collapse (body)
//
// Group your navbar content into this for easy collapsing and expanding across
// various device sizes. By default, this content is collapsed when <768px, but
// will expand past that for a horizontal display.
//
// To start (on mobile devices) the navbar links, forms, and buttons are stacked
// vertically and include a `max-height` to overflow in case you have too much
// content for the user's viewport.

.navbar-collapse {
  box-shadow: none;
  padding-right: 21px;
  padding-left: 21px;

  .navbar-form:first-child {
    border: none;
  }

  @media (min-width: $grid-float-breakpoint) {
    // Account for first and last children spacing
    .navbar-nav.navbar-left:first-child {
      margin-left: -21px;

      > li:first-child a {
        //@include border-left-radius($navbar-border-radius);
      }
    }

    .navbar-nav.navbar-right:last-child {
      margin-right: -21px;

      > .dropdown:last-child > a {
        border-radius: 0 $navbar-border-radius $navbar-border-radius 0;
      }
    }

    .navbar-form.navbar-right:last-child {
      .navbar-fixed-top &,
      .navbar-fixed-bottom & {
        margin-right: 0;
      }
    }
  }
  @media (max-width: $grid-float-breakpoint-max) {
    .navbar-nav.navbar-right:last-child {
      margin-bottom: 3px;
    }
  }
}

// Both navbar header and collapse
//
// When a container is present, change the behavior of the header and collapse.

.navbar {
  .container,
  .container-fluid {
    padding-left: 21px;
    padding-right: 21px;

    > .navbar-header,
    > .navbar-collapse {
      margin-right: -21px;
      margin-left: -21px;

      @media (min-width: $grid-float-breakpoint) {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}

//
// Navbar alignment options
//
// Display the navbar across the entirety of the page or fixed it to the top or
// bottom of the page.

// Static top (unfixed, but 100% wide) navbar
.navbar-static-top {
  z-index: $zindex-navbar;
  border-width: 0;
  border-radius: 0;
}

// Fix the top/bottom navbars when screen real estate supports it
.navbar-fixed-top,
.navbar-fixed-bottom {
  z-index: $zindex-navbar-fixed;
  border-radius: 0;
}

.navbar-fixed-top {
  border-width: 0;
}

.navbar-fixed-bottom {
  margin-bottom: 0; // override .navbar defaults
  border-width: 0;
}

// Brand/project name

.navbar-brand {
  font-size: floor(($component-font-size-base * 1.6)); // ~24px
  line-height: 1.042; // ~25px
  height: $navbar-height-base;
  font-weight: 700;
  padding: (($navbar-height-base - 25px) / 2) 21px;

  > [class*="fui-"] {
    font-size: floor(($component-font-size-base * 1.267)); // ~19px
    line-height: 1.263; // ~24px
    vertical-align: top;
  }

  @media (min-width: $grid-float-breakpoint) {
    .navbar > .container &,
    .navbar > .container-fluid & {
      margin-left: -21px;
    }
  }
}

// Navbar toggle
//
// Custom button for toggling the `.navbar-collapse`, powered by the collapse
// JavaScript plugin.

.navbar-toggle {
  border: none;
  color: $brand-primary;
  margin: 0 0 0 21px;
  padding: 0 21px;
  height: $navbar-height-base;
  line-height: $navbar-height-base;

  &:before {
    color: $link-color;
    content: "\e61a";
    font-family: "Flat-UI-Pro-Icons";
    font-size: floor(($component-font-size-base * 1.467)); // ~22px
    font-style: normal;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: color .25s linear;
  }

  &:hover,
  &:focus {
    outline: none;

    &:before {
      color: $link-hover-color;
    }
  }

  .icon-bar {
    display: none;
  }

  @media (min-width: $grid-float-breakpoint) {
    display: none;
  }
}

// Navbar nav links
//
// Builds on top of the `.nav` components with it's own modifier class to make
// the nav the full height of the horizontal nav (above 768px).

.navbar-nav {
  margin: 0;

  > li > a {
    font-size: floor(($component-font-size-base * 1.067)); // ~16px
    padding: (($navbar-height-base - 23px) / 2) 21px;
    line-height: 23px;
    font-weight: 700;
  }

  > li > a:hover,
  > li > a:focus,
  .open > a:focus,
  .open > a:hover {
    background-color: transparent;
  }

  [class^="fui-"] {
    line-height: 20px;
    position: relative;
    top: 1px;
  }

  .visible-sm,
  .visible-xs {
    > [class^="fui-"] {
      margin-left: 12px;
    }
  }

  @media (max-width: $grid-float-breakpoint-max) {
    margin: 0 -21px;

    // Dropdowns get custom display when collapsed
    .open .dropdown-menu {
      > li > a,
      .dropdown-header {
        padding: 7px 15px 7px 31px !important;
      }

      > li > a {
        line-height: 23px;
      }
    }

    > li > a {
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }
}

// Navbar form
//
// Extension of the `.form-inline` with some extra flavor for optimum display in
// our navbars.

//less raw
//.navbar-input {
//  .input-size(@input-height-sm; 5px; 10px; @input-font-size-sm; @navbar-input-line-height);
//}

//.navbar-vertical-align(@element-height; @navbar-height: @navbar-height-base) {
//  padding-top: ((@navbar-height - @element-height) / 2);
//  padding-bottom: ((@navbar-height - @element-height) / 2);
//}

.navbar-form {
  box-shadow: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 19px;
  padding-left: 19px;
  padding-top: (($navbar-height-base - $input-height-sm) / 2);
  padding-bottom: (($navbar-height-base - $input-height-sm) / 2);

  // Vertically center in expanded, horizontal navbar
  //.navbar-vertical-align(@input-height-sm);
  //
  //@media (max-width: @grid-float-breakpoint-max) {
  //  margin: 3px -21px;
  //  width: auto;
  //}

  // Controls sizing
  .form-control,
  .input-group-addon,
  //.btn {
  //  .navbar-input();
  //}

  .btn {
    margin: 0;
  }

  // Reset rounded corners
  //.form-controls-corners-reset();

  .form-control {
    font-size: $component-font-size-base;
    border-radius: 5px;
    display: table-cell;
  }

  .form-group ~ .btn {
    font-size: $component-font-size-base;
    border-radius: 5px;
    margin-left: 5px;
  }

  .form-group + .btn {
    margin-right: 5px;
  }

  @media (min-width: $grid-float-breakpoint) {
    .input-group { // Width fix for Webkit and IE11
      width: 195px;
    }
  }

  @media (max-width: $grid-float-breakpoint-max) {
    .form-group {
      margin-bottom: 7px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .form-group + .btn {
      margin-left: 0;
    }
  }
}

// Dropdown menus

// Menu position and menu carets
.navbar-nav > li {
  > .dropdown-menu {
    min-width: 100%;
    margin-top: 9px;
    border-radius: $border-radius-base;
  }

  &.open > .dropdown-menu {
    @media (max-width: $grid-float-breakpoint-max) {
      margin-top: 0 !important;
    }
  }
}

// Menu position and menu caret support for dropups via extra dropup class
.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  //@include border-bottom-radius($border-radius-base);
}

.navbar-nav > .open > .dropdown-toggle,
.navbar-nav > .open > .dropdown-toggle:focus,
.navbar-nav > .open > .dropdown-toggle:hover {
  background-color: transparent;
}

// Text in navbars
//
// Add a class to make any element properly align itself vertically within the navbars.

.navbar-text {
  font-size: floor(($component-font-size-base * 1.067)); // ~16px
  line-height: 1.438; // ~23px
  color: $brand-primary;
  margin-top: 0;
  margin-bottom: 0;

  //@include navbar-vertical-align(23px, $navbar-height-base);

  @media (min-width: $grid-float-breakpoint) {
    margin-left: 21px;
    margin-right: 21px;

    // Outdent the form if last child to line up with content down the page
    &.navbar-right:last-child {
      margin-right: 0;
    }
  }
}

// Buttons in navbars
//
// Vertically center a button within a navbar (when *not* in a form).

.navbar-btn {
  margin-top: (($navbar-height-base - $input-height-base) / 2);
  margin-bottom: (($navbar-height-base - $input-height-base) / 2);

  &.btn-sm {
    margin-top: (($navbar-height-base - $input-height-sm) / 2);
    margin-bottom: ((($navbar-height-base - $input-height-sm) / 2) - 1);
  }

  &.btn-xs {
    margin-top: (($navbar-height-base - 25) / 2);
    margin-bottom: (($navbar-height-base - 25) / 2);
  }
}

// Unread icon
//
.navbar-unread,
.navbar-new {
  font-family: $font-family-base;
  background-color: $brand-secondary;
  border-radius: 50%;
  color: $inverse;
  font-size: 0;
  font-weight: 700;
  height: 6px;
  line-height: 1;
  position: absolute;
  right: 12px;
  text-align: center;
  top: 35%;
  width: 6px;
  z-index: 10;

  @media (max-width: $grid-float-breakpoint) {
    position: static;
    float: right;
    margin: 0 0 0 10px;
  }

  .active & {
    background-color: $inverse;
    display: none;
  }
}

.navbar-new {
  background-color: $brand-danger;
  font-size: 12px;
  height: 18px;
  line-height: 17px;
  margin: -6px -10px;
  min-width: 18px;
  padding: 0 1px;
  width: auto;
  -webkit-font-smoothing: subpixel-antialiased;
}

// Alternate navbars
// --------------------------------------------------

// Default navbar
.navbar-default {
  background-color: $navbar-default-bg;

  .navbar-brand {
    color: $navbar-default-brand-color;

    &:hover,
    &:focus {
      color: $navbar-default-brand-hover-color;
      background-color: $navbar-default-brand-hover-bg;
    }
  }

  .navbar-toggle {
    &:before {
      color: $navbar-default-toggle-color;
    }

    &:hover,
    &:focus {
      background-color: transparent;

      &:before {
        color: $navbar-default-toggle-hover-color;
      }
    }
  }

  .navbar-collapse,
  .navbar-form {
    border-color: $navbar-default-form-border;
    border-width: 2px;
  }

  .navbar-nav {
    > li > a {
      color: $navbar-default-link-color;

      &:hover,
      &:focus {
        color: $navbar-default-link-hover-color;
        background-color: $navbar-default-link-hover-bg;
      }
    }

    > .active > a {
      &,
      &:hover,
      &:focus {
        color: $navbar-default-link-active-color;
        background-color: $navbar-default-link-active-bg;
      }
    }

    > .disabled > a {
      &,
      &:hover,
      &:focus {
        color: $navbar-default-link-disabled-color;
        background-color: $navbar-default-link-disabled-bg;
      }
    }
  }

  // Dropdown menu items and carets
  .navbar-nav {
    // Caret text color
    > .dropdown > a .caret {
      border-top-color: $navbar-default-caret-color;
      border-bottom-color: $navbar-default-caret-color;
    }

    // Caret should match text color on active
    > .active > a .caret {
      border-top-color: $navbar-default-caret-active-color;
      border-bottom-color: $navbar-default-caret-active-color;
    }

    // Caret should match text color on hover
    > .dropdown > a:hover .caret,
    > .dropdown > a:focus .caret {
      border-top-color: $navbar-default-caret-hover-color;
      border-bottom-color: $navbar-default-caret-hover-color;
    }

    // Remove background color from open dropdown
    > .open > a {
      &,
      &:hover,
      &:focus {
        background-color: $navbar-default-link-active-bg;
        color: $navbar-default-link-active-color;

        .caret {
          border-top-color: $navbar-default-caret-active-color;
          border-bottom-color: $navbar-default-caret-active-color;
        }
      }
    }


    @media (max-width: $grid-float-breakpoint-max) {
      // Dropdowns get custom display when collapsed
      .open .dropdown-menu {
        > li > a {
          color: $navbar-default-link-color;

          &:hover,
          &:focus {
            color: $navbar-default-link-hover-color;
            background-color: $navbar-default-link-hover-bg;
          }
        }

        > .active > a {
          &,
          &:hover,
          &:focus {
            color: $navbar-default-link-active-color;
            background-color: $navbar-default-link-active-bg;
          }
        }

        > .disabled > a {
          &,
          &:hover,
          &:focus {
            color: $navbar-default-link-disabled-color;
            background-color: $navbar-default-link-disabled-bg;
          }
        }
      }
    }
  }

  .navbar-form {
    .form-control {
      border-color: transparent;
      //@include placeholder($navbar-default-form-placeholder);

      &:focus {
        border-color: $brand-secondary;
        color: $brand-secondary;
      }
    }

    .input-group-btn .btn {
      border-color: transparent;
      color: $navbar-default-form-icon;
    }

    .input-group.focus {
      .form-control,
      .input-group-btn .btn {
        border-color: $brand-secondary;
        color: $brand-secondary;
      }
    }
  }

  .navbar-text {
    color: $brand-primary;
  }

  // Links in navbars
  //
  // Add a class to ensure links outside the navbar nav are colored correctly.

  .navbar-link {
    color: $navbar-default-link-color;

    &:hover {
      color: $navbar-default-link-hover-color;
    }
  }

  .btn-link {
    color: $navbar-default-link-color;

    &:hover,
    &:focus {
      color: $navbar-default-link-hover-color;
    }

    &[disabled],
    fieldset[disabled] & {
      &:hover,
      &:focus {
        color: $navbar-default-link-disabled-color;
      }
    }
  }
}

// Inverse navbar
.navbar-inverse {
  background-color: $navbar-inverse-bg;

  .navbar-brand {
    color: $navbar-inverse-brand-color;

    &:hover,
    &:focus {
      color: $navbar-inverse-brand-hover-color;
      background-color: $navbar-inverse-brand-hover-bg;
    }
  }

  .navbar-toggle {
    &:before {
      color: $navbar-inverse-toggle-color;
    }

    &:hover,
    &:focus {
      background-color: transparent;

      &:before {
        color: $navbar-inverse-toggle-hover-color;
      }
    }
  }

  .navbar-collapse {
    border-color: $navbar-inverse-form-border;
    border-width: 2px;
  }

  .navbar-nav {
    > li > a {
      color: $navbar-inverse-link-color;

      &:hover,
      &:focus {
        color: $navbar-inverse-link-hover-color;
        background-color: $navbar-inverse-link-hover-bg;
      }
    }

    .active {
      &,
      &:hover,
      &:focus {
        color: $navbar-inverse-link-active-color;
        background-color: $navbar-inverse-link-active-bg;
      }
    }

    > .disabled > a {
      &,
      &:hover,
      &:focus {
        color: $navbar-inverse-link-disabled-color;
        background-color: $navbar-inverse-link-disabled-bg;
      }
    }
  }

  // Dropdown menu items and carets
  .navbar-nav {
    // Caret should match text color on hover
    > .dropdown > a:hover .caret,
    > .dropdown > a:focus .caret {
      border-top-color: $navbar-inverse-caret-hover-color;
      border-bottom-color: $navbar-inverse-caret-hover-color;
    }

    // Remove background color from open dropdown
    > .open > a {
      &,
      &:hover,
      &:focus {
        background-color: $navbar-inverse-link-active-bg;
        color: $navbar-inverse-link-active-color;
        border-left-color: transparent;

        .caret {
          border-top-color: $navbar-inverse-link-active-color;
          border-bottom-color: $navbar-inverse-link-active-color;
        }
      }
    }

    > .dropdown > a .caret {
      border-top-color: $navbar-inverse-caret-color;
      border-bottom-color: $navbar-inverse-caret-color;
    }

    > .open {
      > .dropdown-menu {
        background-color: $navbar-inverse-dropdown-bg;
        padding: 3px 4px;

        > li > a {
          color: $navbar-inverse-dropdown-link-color;
          border-radius: $border-radius-base;
          padding: 6px 9px;

          &:hover,
          &:focus {
            color: $navbar-inverse-dropdown-link-hover-color;
            background-color: $navbar-inverse-dropdown-link-hover-bg;
          }
        }

        > .divider {
          background-color: $navbar-inverse-divider;
          height: 2px;
          margin-left: -4px;
          margin-right: -4px;
        }
      }
    }

    @media (max-width: $grid-float-breakpoint-max) {
      > li > a {
        border-left-width: 0;
      }

      // Dropdowns get custom display when collapsed
      .open .dropdown-menu {
        > li > a {
          color: $navbar-inverse-link-color;

          &:hover,
          &:focus {
            color: $navbar-inverse-link-hover-color;
            background-color: $navbar-inverse-link-hover-bg;
          }
        }

        > .active > a {
          &,
          &:hover,
          &:focus {
            color: $navbar-inverse-link-active-color;
            background-color: $navbar-inverse-link-active-bg;
          }
        }

        > .disabled > a {
          &,
          &:hover,
          &:focus {
            color: $navbar-inverse-link-disabled-color;
            background-color: $navbar-inverse-link-disabled-bg;
          }
        }
      }

      // Custom background for dividers when collapsed
      .dropdown-menu .divider {
        background-color: $navbar-inverse-divider;
      }
    }
  }

  .navbar-form {
    .form-control {
      color: $navbar-inverse-form-placeholder;
      border-color: transparent;
      background-color: $navbar-inverse-form-bg;
      height: 35px;

      &:focus {
        border-color: $brand-secondary;
        color: $brand-secondary;
      }
    }

    .input-group-btn .btn {
      border-color: transparent;
      background-color: $navbar-inverse-form-bg;
      color: $navbar-inverse-form-icon;
    }

    .input-group.focus {
      .form-control,
      .input-group-btn .btn {
        border-color: $brand-secondary;
        color: $brand-secondary;
      }
    }

    @media (max-width: $grid-float-breakpoint-max) {
      border-color: $navbar-inverse-form-border;
      border-width: 2px 0;
    }
  }

  .navbar-text {
    color: $inverse;

    a {
      color: $navbar-inverse-link-color;

      &:hover,
      &:focus {
        color: $navbar-inverse-link-hover-color;
      }
    }
  }

  .navbar-btn {
    //@include button-variant($btn-default-color, $brand-secondary, $btn-primary-hover-bg, $btn-primary-active-bg);
  }
}

// Embossed navbar
.navbar-embossed {
  @media (min-width: $grid-float-breakpoint) {
    > .navbar-collapse {
      border-radius: $navbar-border-radius;
      box-shadow: inset 0 -2px 0 fade(black, 15%);
    }

    &.navbar-inverse .navbar-nav {
      .active > a,
      .open > a {
        box-shadow: inset 0 -2px 0 fade(black, 15%);
      }
    }
  }
}

// Large navbar
.navbar-lg {
  min-height: $navbar-height-large;

  .navbar-brand {
    line-height: 1;
    height: $navbar-height-large;
    padding-top: (($navbar-height-large - 24px) / 2);
    padding-bottom: (($navbar-height-large - 24px) / 2);

    > [class*="fui-"] {
      font-size: floor(($component-font-size-base * 1.6)); // ~24px
      line-height: 1;
    }
  }

  .navbar-nav {
    > li > a {
      font-size: $component-font-size-base;
      line-height: 1.6;

      @media (min-width: $grid-float-breakpoint) {
        padding-top: (($navbar-height-large - 24px) / 2);
        padding-bottom: (($navbar-height-large - 24px) / 2);
      }
    }
  }

  .navbar-toggle {
    height: $navbar-height-large;
    line-height: $navbar-height-large;
  }

  .navbar-form {
    padding-top: (($navbar-height-large - $input-height-base) / 2);
    padding-bottom: (($navbar-height-large - $input-height-base) / 2);

    //@include navbar-vertical-align($input-height-sm; $navbar-height-large);
  }

  .navbar-text {
    //@include navbar-vertical-align(23px; $navbar-height-large);
  }

  .navbar-btn {
    margin-top: (($navbar-height-large - $input-height-base) / 2);
    margin-bottom: (($navbar-height-large - $input-height-base) / 2);

    &.btn-sm {
      margin-top: (($navbar-height-large - $input-height-sm) / 2);
      margin-bottom: (($navbar-height-large - $input-height-sm) / 2);
    }

    &.btn-xs {
      margin-top: (($navbar-height-large - 25px) / 2);
      margin-bottom: (($navbar-height-large - 25px) / 2);
    }
  }
}

// typeahead and global searcher
.campaign-searcher {
  padding: 0;
  margin-right: 0px !important;

  .rbt-input-main {
    width: 275px;
    transition: all 0.25s cubic-bezier(0.72, 0, 0.4, 1);
    &:focus {
      width: 300px;
      color: $brand-secondary;
    }
  }

  .dropdown-menu {
    width: 450px;
    top: 0 !important;
    left: -16px !important;

    li {
      position: relative;
      border-bottom: 1px dashed $table-border-color;

      &:last-child {
        border-bottom: none;
      }

      a {
        position: relative;
        font-weight: $headings-font-weight;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 12px 35px;
      }
    }
  }
}
