//
// Buttons
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.btn {
  border: none;
  font-size: $btn-font-size-base;
  font-weight: $btn-font-weight;
  line-height: $btn-line-height-base;
  border-radius: $border-radius-base;
  padding: 10px 15px;
  -webkit-font-smoothing: subpixel-antialiased;
  transition: border .25s linear, color .25s linear, background-color .25s linear;
  

  &:hover,
  &:focus {
    outline: none;
    color: $btn-default-color;    
  }

  &:active,
  &.active {
    outline: none;
    box-shadow: none;
  }

  &:focus:active {
    outline: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    background-color: $btn-link-disabled-color;
    color: fade($btn-default-color, 75%);
    opacity: .7;
  }

  // Button icons
  [class^="fui-"] {
    margin: 0 1px;
    position: relative;
    line-height: 1;
    top: 1px;
    
    &.btn-xs {
      font-size: 11px;
      top: 0;
    }
    &.btn-hg {
      top: 2px;
    } 
  }  
}

// Alternate buttons
// --------------------------------------------------

.btn-default {
  color: white;
  background-color: #bbc4cd;
}
// .button-variant(@color; @background; @hover-background; @active-background; @disabled-background: @gray-light) {
//   color: @color;
//   background-color: @background;

//   &:hover,
//   &.hover,
//   &:focus,
//   &:active,
//   &.active,
//   .open > .dropdown-toggle& {
//     color: @color;
//     background-color: @hover-background;
//     border-color: @hover-background;
//   }
//   &:active,
//   &.active,
//   .open > .dropdown-toggle& {
//     background: @active-background;
//     border-color: @active-background;
//   }
//   &.disabled,
//   &[disabled],
//   fieldset[disabled] & {
//     &,
//     &:hover,
//     &.hover,
//     &:focus,
//     &:active,
//     &.active {
//       background-color: @disabled-background;
//       border-color: @background;
//     }
//   }

//   .badge {
//     color: @background;
//     background-color: @inverse;
//   }
// }

.btn-primary {
  color: $btn-default-color;
  background-color: $brand-secondary;

  &:hover,
  &:focus,
  &.active
  &.open > .dropdown-toggle
   {
    color: $btn-default-color;
    background-color: $btn-primary-hover-bg;
    border-color: $btn-primary-active-bg;
  }
}

.btn-info    {
  // @include button-variant($btn-default-color, $brand-info, $btn-info-hover-bg, $btn-info-active-bg);
  // .button-variant(@color; @background; @hover-background; @active-background; @disabled-background: @gray-light)

  color: $btn-default-color;
  background-color: $brand-info;

  &:hover,
  &:focus,
  &.active
  &.open > .dropdown-toggle
   {
    color: $btn-default-color;
    background-color: $btn-info-hover-bg;
    border-color: $btn-info-active-bg;
  }
}
// .btn-danger  {
//   @include button-variant($btn-default-color, $brand-danger, $btn-danger-hover-bg, $btn-danger-active-bg);
// }
// .btn-success {
//   @include button-variant($btn-default-color, $brand-success, $btn-success-hover-bg, $btn-success-active-bg);
// }
// .btn-warning {
//   @include button-variant($btn-default-color, $brand-warning, $btn-warning-hover-bg, $btn-warning-active-bg);
// }

.btn-embossed {
  box-shadow: inset 0 -2px 0 fade(black, 15%);
  
  &.active,
  &:active {
    box-shadow: inset 0 2px 0 fade(black, 15%);
  }
}
.btn-wide {
  min-width: 140px;
  padding-left: 30px;
  padding-right: 30px;
}
  
 
// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
  color: $link-color;

  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: underline;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: $btn-link-disabled-color;
      text-decoration: none;
    }
  }
} 
  

// Button sizes
// --------------------------------------------------

// .btn-hg {
//   @include button-size(13px, 20px, $btn-font-size-hg, $btn-line-height-hg, $border-radius-large);
// }
// .btn-lg {
//   @include button-size(10px, 19px, $btn-font-size-lg, $btn-line-height-lg, $border-radius-large);
// }
// .btn-sm {
//   @include button-size(9px, 13px, $btn-font-size-sm, $btn-line-height-sm, $border-radius-base);
// }
// .btn-xs {
//   @include button-size(6px, 9px, $btn-font-size-xs, $btn-line-height-xs, $border-radius-small);
// }  
  

// Button tip
.btn-tip {
  font-weight: 300;
  padding-left: 10px;
  font-size: 92%;
}

// Block button
// --------------------------------------------------

.btn-block {
  white-space: normal;
}

// Social Buttons
// --------------------------------------------------

// [class*="btn-social-"] {
//   @include button-size(10px, 15px, $btn-social-font-size-base, $btn-social-line-height-base, $border-radius-base);
// }

// Set the backgrounds
// -------------------------
// .btn-social-pinterest {
//   @include social-button-variant($btn-default-color, $social-pinterest);
// }
// .btn-social-linkedin {
//   .social-button-variant($btn-default-color, $social-linkedin)
// }
// .btn-social-stumbleupon {
//   @include social-button-variant($btn-default-color, $social-stumbleupon);
// }
// .btn-social-googleplus {
//   @include social-button-variant($btn-default-color, $social-googleplus);
// }
// .btn-social-facebook {
//   @include social-button-variant($btn-default-color, $social-facebook);
// }
// .btn-social-twitter {
//   @include social-button-variant($btn-default-color, $social-twitter);
// }