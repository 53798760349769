@font-face {
  font-family: 'Flaticons Stroke';
  src: url(#{$icon-font-path}flaticons-stroke.eot),
  url(#{$icon-font-path}flaticons-stroke.eot) format('embedded-opentype'),
  url(#{$icon-font-path}flaticons-stroke.woff) format('woff'),
  url(#{$icon-font-path}flaticons-stroke.ttf) format('truetype'),
  url(#{$icon-font-path}flaticons-stroke.svg) format('svg');
  font-weight: normal;
  font-style: normal;
}

.flaticon.stroke:before {
  font-family: 'Flaticons Stroke';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased; }

.flaticon.stroke.grid-1:before,
.flaticon.stroke.grid:before,
.flaticon.stroke.A1:before { content: "\e3e8"; }

.flaticon.stroke.grid-2:before,
.flaticon.stroke.A2:before { content: "\e3e9"; }

.flaticon.stroke.grid-3:before,
.flaticon.stroke.A3:before { content: "\e3ea"; }

.flaticon.stroke.window-1:before,
.flaticon.stroke.window:before,
.flaticon.stroke.A4:before { content: "\e3eb"; }

.flaticon.stroke.collection-1:before,
.flaticon.stroke.collection:before,
.flaticon.stroke.A5:before { content: "\e3ec"; }

.flaticon.stroke.switch-window-1:before,
.flaticon.stroke.switch-window:before,
.flaticon.stroke.A6:before { content: "\e3ed"; }

.flaticon.stroke.add-window-1:before,
.flaticon.stroke.add-window:before,
.flaticon.stroke.A7:before { content: "\e3ee"; }

.flaticon.stroke.browser-1:before,
.flaticon.stroke.browser:before,
.flaticon.stroke.A8:before { content: "\e3ef"; }

.flaticon.stroke.minimize-browser-1:before,
.flaticon.stroke.minimize-browser:before,
.flaticon.stroke.A9:before { content: "\e3f0"; }

.flaticon.stroke.new-browser-1:before,
.flaticon.stroke.new-browser:before,
.flaticon.stroke.A10:before { content: "\e3f1"; }

.flaticon.stroke.close-browser-1:before,
.flaticon.stroke.close-browser:before,
.flaticon.stroke.A11:before { content: "\e3f2"; }

.flaticon.stroke.browser-2:before,
.flaticon.stroke.A12:before { content: "\e3f3"; }

.flaticon.stroke.minimize-browser-2:before,
.flaticon.stroke.A13:before { content: "\e3f4"; }

.flaticon.stroke.new-browser-2:before,
.flaticon.stroke.A14:before { content: "\e3f5"; }

.flaticon.stroke.close-browser-2:before,
.flaticon.stroke.A15:before { content: "\e3f6"; }

.flaticon.stroke.multiple-browsers-1:before,
.flaticon.stroke.multiple-browsers:before,
.flaticon.stroke.A16:before { content: "\e3f7"; }

.flaticon.stroke.browser-3:before,
.flaticon.stroke.A17:before { content: "\e3f8"; }

.flaticon.stroke.minimize-browser-3:before,
.flaticon.stroke.A18:before { content: "\e3f9"; }

.flaticon.stroke.new-browser-3:before,
.flaticon.stroke.A19:before { content: "\e3fa"; }

.flaticon.stroke.close-browser-3:before,
.flaticon.stroke.A20:before { content: "\e3fb"; }

.flaticon.stroke.folder-1:before,
.flaticon.stroke.folder:before,
.flaticon.stroke.B1:before { content: "\e3fc"; }

.flaticon.stroke.add-folder-1:before,
.flaticon.stroke.add-folder:before,
.flaticon.stroke.B2:before { content: "\e3fd"; }

.flaticon.stroke.remove-folder-1:before,
.flaticon.stroke.remove-folder:before,
.flaticon.stroke.B3:before { content: "\e3fe"; }

.flaticon.stroke.upload-to-folder-1:before,
.flaticon.stroke.upload-to-folder:before,
.flaticon.stroke.B4:before { content: "\e3ff"; }

.flaticon.stroke.download-to-folder-1:before,
.flaticon.stroke.download-to-folder:before,
.flaticon.stroke.B5:before { content: "\e400"; }

.flaticon.stroke.folder-2:before,
.flaticon.stroke.B6:before { content: "\e401"; }

.flaticon.stroke.open-folder-1:before,
.flaticon.stroke.open-folder:before,
.flaticon.stroke.B7:before { content: "\e402"; }

.flaticon.stroke.add-to-folder-1:before,
.flaticon.stroke.add-to-folder:before,
.flaticon.stroke.B8:before { content: "\e403"; }

.flaticon.stroke.document-1:before,
.flaticon.stroke.document:before,
.flaticon.stroke.B9:before { content: "\e404"; }

.flaticon.stroke.document-2:before,
.flaticon.stroke.B10:before { content: "\e405"; }

.flaticon.stroke.document-3:before,
.flaticon.stroke.B11:before { content: "\e406"; }

.flaticon.stroke.document-4:before,
.flaticon.stroke.B12:before { content: "\e407"; }

.flaticon.stroke.multiple-documents-1:before,
.flaticon.stroke.multiple-documents:before,
.flaticon.stroke.B13:before { content: "\e408"; }

.flaticon.stroke.remove-documents-1:before,
.flaticon.stroke.remove-documents:before,
.flaticon.stroke.B14:before { content: "\e409"; }

.flaticon.stroke.add-document-1:before,
.flaticon.stroke.add-document:before,
.flaticon.stroke.B15:before { content: "\e40a"; }

.flaticon.stroke.close-document-1:before,
.flaticon.stroke.close-document:before,
.flaticon.stroke.B16:before { content: "\e40b"; }

.flaticon.stroke.upload-document-1:before,
.flaticon.stroke.upload-document:before,
.flaticon.stroke.B17:before { content: "\e40c"; }

.flaticon.stroke.download-document-1:before,
.flaticon.stroke.download-document:before,
.flaticon.stroke.B18:before { content: "\e40d"; }

.flaticon.stroke.import-document-1:before,
.flaticon.stroke.import-document:before,
.flaticon.stroke.B19:before { content: "\e40e"; }

.flaticon.stroke.export-document-1:before,
.flaticon.stroke.export-document:before,
.flaticon.stroke.B20:before { content: "\e40f"; }

.flaticon.stroke.clipboard-1:before,
.flaticon.stroke.clipboard:before,
.flaticon.stroke.copy-1:before,
.flaticon.stroke.copy:before,
.flaticon.stroke.C1:before { content: "\e410"; }

.flaticon.stroke.clipboard-1a:before,
.flaticon.stroke.copy-2:before,
.flaticon.stroke.C2:before { content: "\e411"; }

.flaticon.stroke.clipboard-1b:before,
.flaticon.stroke.copy-3:before,
.flaticon.stroke.C3:before { content: "\e412"; }

.flaticon.stroke.clipboard-2:before,
.flaticon.stroke.copy-4:before,
.flaticon.stroke.C4:before { content: "\e413"; }

.flaticon.stroke.remove-from-clipboard-1:before,
.flaticon.stroke.remove-from-clipboard:before,
.flaticon.stroke.C5:before { content: "\e414"; }

.flaticon.stroke.add-to-clipboard-1:before,
.flaticon.stroke.add-to-clipboard:before,
.flaticon.stroke.copy-5:before,
.flaticon.stroke.C6:before { content: "\e415"; }

.flaticon.stroke.add-to-clipboard-2:before,
.flaticon.stroke.C7:before { content: "\e416"; }

.flaticon.stroke.clipboard-2a:before,
.flaticon.stroke.C8:before { content: "\e417"; }

.flaticon.stroke.clipboard-2b:before,
.flaticon.stroke.C9:before { content: "\e418"; }

.flaticon.stroke.comment-1:before,
.flaticon.stroke.comment:before,
.flaticon.stroke.C10:before { content: "\e419"; }

.flaticon.stroke.thinking-comment-1:before,
.flaticon.stroke.thinking-comment:before,
.flaticon.stroke.C11:before { content: "\e41a"; }

.flaticon.stroke.writing-comment-1:before,
.flaticon.stroke.writing-comment:before,
.flaticon.stroke.C12:before { content: "\e41b"; }

.flaticon.stroke.posted-comment:before,
.flaticon.stroke.C13:before { content: "\e41c"; }

.flaticon.stroke.comment-2:before,
.flaticon.stroke.C14:before { content: "\e41d"; }

.flaticon.stroke.thinking-comment-2:before,
.flaticon.stroke.C15:before { content: "\e41e"; }

.flaticon.stroke.writing-comment-2:before,
.flaticon.stroke.C16:before { content: "\e41f"; }

.flaticon.stroke.posted-comment-2:before,
.flaticon.stroke.C17:before { content: "\e420"; }

.flaticon.stroke.chat-1:before,
.flaticon.stroke.chat:before,
.flaticon.stroke.C18:before { content: "\e421"; }

.flaticon.stroke.chat-2:before,
.flaticon.stroke.C19:before { content: "\e422"; }

.flaticon.stroke.chat-3:before,
.flaticon.stroke.C20:before { content: "\e423"; }

.flaticon.stroke.lock-1:before,
.flaticon.stroke.lock:before,
.flaticon.stroke.D1:before { content: "\e424"; }

.flaticon.stroke.unlock-1:before,
.flaticon.stroke.unlock:before,
.flaticon.stroke.D2:before { content: "\e425"; }

.flaticon.stroke.lock-2:before,
.flaticon.stroke.D3:before { content: "\e426"; }

.flaticon.stroke.lock-3:before,
.flaticon.stroke.D4:before { content: "\e427"; }

.flaticon.stroke.unlock-3:before,
.flaticon.stroke.D5:before { content: "\e428"; }

.flaticon.stroke.key-1:before,
.flaticon.stroke.key:before,
.flaticon.stroke.password-1:before,
.flaticon.stroke.password:before,
.flaticon.stroke.D6:before { content: "\e429"; }

.flaticon.stroke.key-2:before,
.flaticon.stroke.password-2:before,
.flaticon.stroke.D7:before { content: "\e42a"; }

.flaticon.stroke.bell-1:before,
.flaticon.stroke.bell:before,
.flaticon.stroke.notification-1:before,
.flaticon.stroke.notification:before,
.flaticon.stroke.D8:before { content: "\e42b"; }

.flaticon.stroke.bell-2:before,
.flaticon.stroke.notification-2:before,
.flaticon.stroke.D9:before { content: "\e42c"; }

.flaticon.stroke.bell-3:before,
.flaticon.stroke.service-1:before,
.flaticon.stroke.service:before,
.flaticon.stroke.D10:before { content: "\e42d"; }

.flaticon.stroke.print-1:before,
.flaticon.stroke.print:before,
.flaticon.stroke.D11:before { content: "\e42e"; }

.flaticon.stroke.calculator-1:before,
.flaticon.stroke.calculator:before,
.flaticon.stroke.D12:before { content: "\e42f"; }

.flaticon.stroke.cloud-1:before,
.flaticon.stroke.cloud:before,
.flaticon.stroke.D13:before { content: "\e430"; }

.flaticon.stroke.cloud-upload-1:before,
.flaticon.stroke.cloud-upload:before,
.flaticon.stroke.D14:before { content: "\e431"; }

.flaticon.stroke.cloud-download-1:before,
.flaticon.stroke.cloud-download:before,
.flaticon.stroke.D15:before { content: "\e432"; }

.flaticon.stroke.cloud-add-1:before,
.flaticon.stroke.cloud-add:before,
.flaticon.stroke.D16:before { content: "\e433"; }

.flaticon.stroke.cloud-2:before,
.flaticon.stroke.D17:before { content: "\e434"; }

.flaticon.stroke.home-1:before,
.flaticon.stroke.home:before,
.flaticon.stroke.house-1:before,
.flaticon.stroke.house:before,
.flaticon.stroke.D18:before { content: "\e435"; }

.flaticon.stroke.home-2:before,
.flaticon.stroke.house-2:before,
.flaticon.stroke.D19:before { content: "\e436"; }

.flaticon.stroke.home-3:before,
.flaticon.stroke.house-3:before,
.flaticon.stroke.D20:before { content: "\e437"; }

.flaticon.stroke.open-book-1:before,
.flaticon.stroke.open-book:before,
.flaticon.stroke.E1:before { content: "\e438"; }

.flaticon.stroke.open-book-2:before,
.flaticon.stroke.E2:before { content: "\e439"; }

.flaticon.stroke.open-book-3:before,
.flaticon.stroke.E3:before { content: "\e43a"; }

.flaticon.stroke.open-book-4:before,
.flaticon.stroke.E4:before { content: "\e43b"; }

.flaticon.stroke.book-1:before,
.flaticon.stroke.book:before,
.flaticon.stroke.E5:before { content: "\e43c"; }

.flaticon.stroke.book-2:before,
.flaticon.stroke.E6:before { content: "\e43d"; }

.flaticon.stroke.book-3:before,
.flaticon.stroke.E7:before { content: "\e43e"; }

.flaticon.stroke.book-4:before,
.flaticon.stroke.E8:before { content: "\e43f"; }

.flaticon.stroke.book-5:before,
.flaticon.stroke.E9:before { content: "\e440"; }

.flaticon.stroke.book-6:before,
.flaticon.stroke.E10:before { content: "\e441"; }

.flaticon.stroke.newspaper-1:before,
.flaticon.stroke.newspaper:before,
.flaticon.stroke.E11:before { content: "\e442"; }

.flaticon.stroke.newspaper-2:before,
.flaticon.stroke.E12:before { content: "\e443"; }

.flaticon.stroke.newspaper-3:before,
.flaticon.stroke.E13:before { content: "\e444"; }

.flaticon.stroke.newspaper-4:before,
.flaticon.stroke.E14:before { content: "\e445"; }

.flaticon.stroke.journal-1:before,
.flaticon.stroke.journal:before,
.flaticon.stroke.E15:before { content: "\e446"; }

.flaticon.stroke.journal-2:before,
.flaticon.stroke.E16:before { content: "\e447"; }

.flaticon.stroke.journal-3:before,
.flaticon.stroke.E17:before { content: "\e448"; }

.flaticon.stroke.notebook-1:before,
.flaticon.stroke.notebook:before,
.flaticon.stroke.E18:before { content: "\e449"; }

.flaticon.stroke.notebook-2:before,
.flaticon.stroke.E19:before { content: "\e44a"; }

.flaticon.stroke.notebook-3:before,
.flaticon.stroke.E20:before { content: "\e44b"; }

.flaticon.stroke.article-1:before,
.flaticon.stroke.article:before,
.flaticon.stroke.F1:before { content: "\e44c"; }

.flaticon.stroke.article-2:before,
.flaticon.stroke.F2:before { content: "\e44d"; }

.flaticon.stroke.article-3:before,
.flaticon.stroke.F3:before { content: "\e44e"; }

.flaticon.stroke.notepad-1:before,
.flaticon.stroke.notepad:before,
.flaticon.stroke.F4:before { content: "\e44f"; }

.flaticon.stroke.notepad-2:before,
.flaticon.stroke.F5:before { content: "\e450"; }

.flaticon.stroke.suitcase-1:before,
.flaticon.stroke.suitcase:before,
.flaticon.stroke.portfolio-1:before,
.flaticon.stroke.portfolio:before,
.flaticon.stroke.F6:before { content: "\e451"; }

.flaticon.stroke.suitcase-2:before,
.flaticon.stroke.F7:before { content: "\e452"; }

.flaticon.stroke.briefcase-1:before,
.flaticon.stroke.briefcase:before,
.flaticon.stroke.F8:before { content: "\e453"; }

.flaticon.stroke.toolbox:before,
.flaticon.stroke.F9:before { content: "\e454"; }

.flaticon.stroke.shirt-1:before,
.flaticon.stroke.shirt:before,
.flaticon.stroke.F10:before { content: "\e455"; }

.flaticon.stroke.umbrella-1:before,
.flaticon.stroke.umbrella:before,
.flaticon.stroke.F11:before { content: "\e456"; }

.flaticon.stroke.coffee-1:before,
.flaticon.stroke.coffee:before,
.flaticon.stroke.F12:before { content: "\e457"; }

.flaticon.stroke.glasses-1:before,
.flaticon.stroke.glasses:before,
.flaticon.stroke.F13:before { content: "\e458"; }

.flaticon.stroke.zoom-1:before,
.flaticon.stroke.zoom:before,
.flaticon.stroke.magnifying-glass-1:before,
.flaticon.stroke.magnifying-glass:before,
.flaticon.stroke.F14:before { content: "\e459"; }

.flaticon.stroke.zoom-2:before,
.flaticon.stroke.magnifying-glass-2:before,
.flaticon.stroke.F15:before { content: "\e45a"; }

.flaticon.stroke.zoom-in-1:before,
.flaticon.stroke.zoom-in:before,
.flaticon.stroke.magnifying-glass-3:before,
.flaticon.stroke.F16:before { content: "\e45b"; }

.flaticon.stroke.eye-1:before,
.flaticon.stroke.eye:before,
.flaticon.stroke.view-1:before,
.flaticon.stroke.view:before,
.flaticon.stroke.F17:before { content: "\e45c"; }

.flaticon.stroke.eye-2:before,
.flaticon.stroke.view-2:before,
.flaticon.stroke.F18:before { content: "\e45d"; }

.flaticon.stroke.eye-3:before,
.flaticon.stroke.view-3:before,
.flaticon.stroke.F19:before { content: "\e45e"; }

.flaticon.stroke.eye-4:before,
.flaticon.stroke.view-4:before,
.flaticon.stroke.F20:before { content: "\e45f"; }

.flaticon.stroke.iphone-1:before,
.flaticon.stroke.iphone:before,
.flaticon.stroke.smart-phone-3:before,
.flaticon.stroke.G1:before { content: "\e460"; }

.flaticon.stroke.ipad-mini-1-tablet-2:before,
.flaticon.stroke.G2:before { content: "\e461"; }

.flaticon.stroke.ipad-1:before,
.flaticon.stroke.ipad:before,
.flaticon.stroke.tablet-3:before,
.flaticon.stroke.G3:before { content: "\e462"; }

.flaticon.stroke.macbook-1:before,
.flaticon.stroke.macbook:before,
.flaticon.stroke.laptop-2:before,
.flaticon.stroke.G4:before { content: "\e463"; }

.flaticon.stroke.imac-1:before,
.flaticon.stroke.imac:before,
.flaticon.stroke.computer-2:before,
.flaticon.stroke.G5:before { content: "\e464"; }

.flaticon.stroke.smart-phone-1:before,
.flaticon.stroke.smart-phone:before,
.flaticon.stroke.G6:before { content: "\e465"; }

.flaticon.stroke.tablet-1:before,
.flaticon.stroke.tablet:before,
.flaticon.stroke.G7:before { content: "\e466"; }

.flaticon.stroke.laptop-1:before,
.flaticon.stroke.laptop:before,
.flaticon.stroke.G8:before { content: "\e467"; }

.flaticon.stroke.computer-1:before,
.flaticon.stroke.computer:before,
.flaticon.stroke.G9:before { content: "\e468"; }

.flaticon.stroke.smart-phone-2:before,
.flaticon.stroke.G10:before { content: "\e469"; }

.flaticon.stroke.photo-1:before,
.flaticon.stroke.photo:before,
.flaticon.stroke.image-1:before,
.flaticon.stroke.image:before,
.flaticon.stroke.G11:before { content: "\e46a"; }

.flaticon.stroke.photo-gallery-1:before,
.flaticon.stroke.photo-gallery:before,
.flaticon.stroke.G12:before { content: "\e46b"; }

.flaticon.stroke.media-gallery-1:before,
.flaticon.stroke.media-gallery:before,
.flaticon.stroke.image-gallery-1:before,
.flaticon.stroke.image-gallery:before,
.flaticon.stroke.G13:before { content: "\e46c"; }

.flaticon.stroke.video-camera-1:before,
.flaticon.stroke.video-camera:before,
.flaticon.stroke.video-1:before,
.flaticon.stroke.video:before,
.flaticon.stroke.G14:before { content: "\e46d"; }

.flaticon.stroke.tv-1:before,
.flaticon.stroke.tv:before,
.flaticon.stroke.television-1:before,
.flaticon.stroke.television:before,
.flaticon.stroke.G15:before { content: "\e46e"; }

.flaticon.stroke.film-strip-1:before,
.flaticon.stroke.film-strip:before,
.flaticon.stroke.film-1:before,
.flaticon.stroke.film:before,
.flaticon.stroke.G16:before { content: "\e46f"; }

.flaticon.stroke.camera-1:before,
.flaticon.stroke.camera:before,
.flaticon.stroke.G17:before { content: "\e470"; }

.flaticon.stroke.camera-2:before,
.flaticon.stroke.G18:before { content: "\e471"; }

.flaticon.stroke.camera-3:before,
.flaticon.stroke.G19:before { content: "\e472"; }

.flaticon.stroke.camera-4:before,
.flaticon.stroke.G20:before { content: "\e473"; }

.flaticon.stroke.calendar-1:before,
.flaticon.stroke.calendar:before,
.flaticon.stroke.H1:before { content: "\e474"; }

.flaticon.stroke.calendar-2:before,
.flaticon.stroke.H2:before { content: "\e475"; }

.flaticon.stroke.calendar-3:before,
.flaticon.stroke.H3:before { content: "\e476"; }

.flaticon.stroke.calendar-4:before,
.flaticon.stroke.H4:before { content: "\e477"; }

.flaticon.stroke.calendar-5:before,
.flaticon.stroke.H5:before { content: "\e478"; }

.flaticon.stroke.taskpad-1:before,
.flaticon.stroke.taskpad:before,
.flaticon.stroke.H6:before { content: "\e479"; }

.flaticon.stroke.inbox-1:before,
.flaticon.stroke.inbox:before,
.flaticon.stroke.H7:before { content: "\e47a"; }

.flaticon.stroke.inbox-checkmark-1:before,
.flaticon.stroke.inbox-checkmark:before,
.flaticon.stroke.H8:before { content: "\e47b"; }

.flaticon.stroke.inbox-upload-1:before,
.flaticon.stroke.inbox-upload:before,
.flaticon.stroke.H9:before { content: "\e47c"; }

.flaticon.stroke.inbox-add-1:before,
.flaticon.stroke.inbox-add:before,
.flaticon.stroke.H10:before { content: "\e47d"; }

.flaticon.stroke.inbox-2:before,
.flaticon.stroke.H11:before { content: "\e47e"; }

.flaticon.stroke.inbox-3:before,
.flaticon.stroke.H12:before { content: "\e47f"; }

.flaticon.stroke.trash-1:before,
.flaticon.stroke.trash:before,
.flaticon.stroke.delete-1:before,
.flaticon.stroke.delete:before,
.flaticon.stroke.H13:before { content: "\e480"; }

.flaticon.stroke.trash-2:before,
.flaticon.stroke.delete-2:before,
.flaticon.stroke.H14:before { content: "\e481"; }

.flaticon.stroke.trash-3:before,
.flaticon.stroke.delete-3:before,
.flaticon.stroke.H15:before { content: "\e482"; }

.flaticon.stroke.trash-4:before,
.flaticon.stroke.delete-4:before,
.flaticon.stroke.H16:before { content: "\e483"; }

.flaticon.stroke.trash-5:before,
.flaticon.stroke.delete-5:before,
.flaticon.stroke.H17:before { content: "\e484"; }

.flaticon.stroke.bucket-1:before,
.flaticon.stroke.bucket:before,
.flaticon.stroke.H18:before { content: "\e485"; }

.flaticon.stroke.paperclip-1:before,
.flaticon.stroke.paperclip:before,
.flaticon.stroke.attachment-1:before,
.flaticon.stroke.attachment:before,
.flaticon.stroke.H19:before { content: "\e486"; }

.flaticon.stroke.paperclip-2:before,
.flaticon.stroke.attachment-2:before,
.flaticon.stroke.H20:before { content: "\e487"; }

.flaticon.stroke.compose-1:before,
.flaticon.stroke.compose:before,
.flaticon.stroke.message-1:before,
.flaticon.stroke.message:before,
.flaticon.stroke.I1:before { content: "\e488"; }

.flaticon.stroke.compose-2:before,
.flaticon.stroke.message-2:before,
.flaticon.stroke.I2:before { content: "\e489"; }

.flaticon.stroke.compose-3:before,
.flaticon.stroke.message-3:before,
.flaticon.stroke.I3:before { content: "\e48a"; }

.flaticon.stroke.pencil-1:before,
.flaticon.stroke.pencil:before,
.flaticon.stroke.edit-1:before,
.flaticon.stroke.edit:before,
.flaticon.stroke.I4:before { content: "\e48b"; }

.flaticon.stroke.pencil-2:before,
.flaticon.stroke.edit-2:before,
.flaticon.stroke.I5:before { content: "\e48c"; }

.flaticon.stroke.brush-1:before,
.flaticon.stroke.brush:before,
.flaticon.stroke.paint-1:before,
.flaticon.stroke.paint:before,
.flaticon.stroke.I6:before { content: "\e48d"; }

.flaticon.stroke.compose-4:before,
.flaticon.stroke.message-4:before,
.flaticon.stroke.I7:before { content: "\e48e"; }

.flaticon.stroke.feather-1:before,
.flaticon.stroke.feather:before,
.flaticon.stroke.I8:before { content: "\e48f"; }

.flaticon.stroke.compose-5:before,
.flaticon.stroke.message-5:before,
.flaticon.stroke.I9:before { content: "\e490"; }

.flaticon.stroke.feather-2:before,
.flaticon.stroke.I10:before { content: "\e491"; }

.flaticon.stroke.mail-1:before,
.flaticon.stroke.mail:before,
.flaticon.stroke.envelope-1:before,
.flaticon.stroke.envelope:before,
.flaticon.stroke.I11:before { content: "\e492"; }

.flaticon.stroke.mail-2:before,
.flaticon.stroke.envelope-2:before,
.flaticon.stroke.I12:before { content: "\e493"; }

.flaticon.stroke.mail-3:before,
.flaticon.stroke.envelope-3:before,
.flaticon.stroke.I13:before { content: "\e494"; }

.flaticon.stroke.mail-4:before,
.flaticon.stroke.envelope-4:before,
.flaticon.stroke.I14:before { content: "\e495"; }

.flaticon.stroke.send-1:before,
.flaticon.stroke.send:before,
.flaticon.stroke.I15:before { content: "\e496"; }

.flaticon.stroke.share-1:before,
.flaticon.stroke.share:before,
.flaticon.stroke.I16:before { content: "\e497"; }

.flaticon.stroke.share-2:before,
.flaticon.stroke.I17:before { content: "\e498"; }

.flaticon.stroke.reply-to-all-1:before,
.flaticon.stroke.reply-to-all:before,
.flaticon.stroke.I18:before { content: "\e499"; }

.flaticon.stroke.reply-1:before,
.flaticon.stroke.reply:before,
.flaticon.stroke.I19:before { content: "\e49a"; }

.flaticon.stroke.forward-1:before,
.flaticon.stroke.forward:before,
.flaticon.stroke.I20:before { content: "\e49b"; }

.flaticon.stroke.star-1:before,
.flaticon.stroke.star:before,
.flaticon.stroke.J1:before { content: "\e49c"; }

.flaticon.stroke.star-2:before,
.flaticon.stroke.J2:before { content: "\e49d"; }

.flaticon.stroke.heart-1:before,
.flaticon.stroke.heart:before,
.flaticon.stroke.J3:before { content: "\e49e"; }

.flaticon.stroke.bookmark-1:before,
.flaticon.stroke.bookmark:before,
.flaticon.stroke.J4:before { content: "\e49f"; }

.flaticon.stroke.bookmark-2:before,
.flaticon.stroke.J5:before { content: "\e4a0"; }

.flaticon.stroke.bookmark-3:before,
.flaticon.stroke.J6:before { content: "\e4a1"; }

.flaticon.stroke.pin-1:before,
.flaticon.stroke.pin:before,
.flaticon.stroke.J7:before { content: "\e4a2"; }

.flaticon.stroke.share-3:before,
.flaticon.stroke.J8:before { content: "\e4a3"; }

.flaticon.stroke.share-4:before,
.flaticon.stroke.J9:before { content: "\e4a4"; }

.flaticon.stroke.share-5:before,
.flaticon.stroke.J10:before { content: "\e4a5"; }

.flaticon.stroke.link-1:before,
.flaticon.stroke.link:before,
.flaticon.stroke.J11:before { content: "\e4a6"; }

.flaticon.stroke.link-2:before,
.flaticon.stroke.J12:before { content: "\e4a7"; }

.flaticon.stroke.link-3:before,
.flaticon.stroke.J13:before { content: "\e4a8"; }

.flaticon.stroke.link-4:before,
.flaticon.stroke.J14:before { content: "\e4a9"; }

.flaticon.stroke.question-mark-1:before,
.flaticon.stroke.question-mark:before,
.flaticon.stroke.help-1:before,
.flaticon.stroke.help:before,
.flaticon.stroke.J15:before { content: "\e4aa"; }

.flaticon.stroke.question-mark-2:before,
.flaticon.stroke.help-2:before,
.flaticon.stroke.J16:before { content: "\e4ab"; }

.flaticon.stroke.exclamation-point-1:before,
.flaticon.stroke.exclamation-point:before,
.flaticon.stroke.warning-1:before,
.flaticon.stroke.warning:before,
.flaticon.stroke.caution-1:before,
.flaticon.stroke.caution:before,
.flaticon.stroke.J17:before { content: "\e4ac"; }

.flaticon.stroke.exclamation-point-1:before,
.flaticon.stroke.exclamation-point:before,
.flaticon.stroke.warning-1:before,
.flaticon.stroke.warning:before,
.flaticon.stroke.caution-1:before,
.flaticon.stroke.caution:before,
.flaticon.stroke.J18:before { content: "\e4ad"; }

.flaticon.stroke.info-1:before,
.flaticon.stroke.info:before,
.flaticon.stroke.about-1:before,
.flaticon.stroke.about:before,
.flaticon.stroke.J19:before { content: "\e4ae"; }

.flaticon.stroke.info-2:before,
.flaticon.stroke.about-2:before,
.flaticon.stroke.J20:before { content: "\e4af"; }

.flaticon.stroke.user-1:before,
.flaticon.stroke.user:before,
.flaticon.stroke.profile-2:before,
.flaticon.stroke.K1:before { content: "\e4b0"; }

.flaticon.stroke.add-user-1:before,
.flaticon.stroke.add-user:before,
.flaticon.stroke.K2:before { content: "\e4b1"; }

.flaticon.stroke.user-2:before,
.flaticon.stroke.profile-3:before,
.flaticon.stroke.K3:before { content: "\e4b2"; }

.flaticon.stroke.user-3:before,
.flaticon.stroke.profile-4:before,
.flaticon.stroke.K4:before { content: "\e4b3"; }

.flaticon.stroke.user-4:before,
.flaticon.stroke.profile-5:before,
.flaticon.stroke.K5:before { content: "\e4b4"; }

.flaticon.stroke.group-1:before,
.flaticon.stroke.group:before,
.flaticon.stroke.follow-1:before,
.flaticon.stroke.follow:before,
.flaticon.stroke.K6:before { content: "\e4b5"; }

.flaticon.stroke.profile-1:before,
.flaticon.stroke.profile:before,
.flaticon.stroke.K7:before { content: "\e4b6"; }

.flaticon.stroke.id-1:before,
.flaticon.stroke.id:before,
.flaticon.stroke.K8:before { content: "\e4b7"; }

.flaticon.stroke.id-2:before,
.flaticon.stroke.K9:before { content: "\e4b8"; }

.flaticon.stroke.contacts-1:before,
.flaticon.stroke.contacts:before,
.flaticon.stroke.K10:before { content: "\e4b9"; }

.flaticon.stroke.contacts-2:before,
.flaticon.stroke.K11:before { content: "\e4ba"; }

.flaticon.stroke.moon-1:before,
.flaticon.stroke.moon:before,
.flaticon.stroke.sleep-1:before,
.flaticon.stroke.sleep:before,
.flaticon.stroke.K12:before { content: "\e4bb"; }

.flaticon.stroke.water-1:before,
.flaticon.stroke.water:before,
.flaticon.stroke.K13:before { content: "\e4bc"; }

.flaticon.stroke.leaf-1:before,
.flaticon.stroke.leaf:before,
.flaticon.stroke.nature-1:before,
.flaticon.stroke.nature:before,
.flaticon.stroke.K14:before { content: "\e4bd"; }

.flaticon.stroke.anchor-1:before,
.flaticon.stroke.anchor:before,
.flaticon.stroke.K15:before { content: "\e4be"; }

.flaticon.stroke.trophy-1:before,
.flaticon.stroke.trophy:before,
.flaticon.stroke.award-1:before,
.flaticon.stroke.award:before,
.flaticon.stroke.K16:before { content: "\e4bf"; }

.flaticon.stroke.gas-1:before,
.flaticon.stroke.gas:before,
.flaticon.stroke.travel-1:before,
.flaticon.stroke.travel:before,
.flaticon.stroke.K17:before { content: "\e4c0"; }

.flaticon.stroke.food-1:before,
.flaticon.stroke.food:before,
.flaticon.stroke.restaurant-1:before,
.flaticon.stroke.restaurant:before,
.flaticon.stroke.K18:before { content: "\e4c1"; }

.flaticon.stroke.building-1:before,
.flaticon.stroke.building:before,
.flaticon.stroke.city-1:before,
.flaticon.stroke.city:before,
.flaticon.stroke.K19:before { content: "\e4c2"; }

.flaticon.stroke.government-1:before,
.flaticon.stroke.government:before,
.flaticon.stroke.politics-1:before,
.flaticon.stroke.politics:before,
.flaticon.stroke.building-2:before,
.flaticon.stroke.K20:before { content: "\e4c3"; }

.flaticon.stroke.cart-1:before,
.flaticon.stroke.cart:before,
.flaticon.stroke.shopping-cart-1:before,
.flaticon.stroke.shopping-cart:before,
.flaticon.stroke.L1:before { content: "\e4c4"; }

.flaticon.stroke.cart-2:before,
.flaticon.stroke.shopping-cart-2:before,
.flaticon.stroke.L2:before { content: "\e4c5"; }

.flaticon.stroke.cart-3:before,
.flaticon.stroke.shopping-cart-3:before,
.flaticon.stroke.L3:before { content: "\e4c6"; }

.flaticon.stroke.tag-1:before,
.flaticon.stroke.tag:before,
.flaticon.stroke.L4:before { content: "\e4c7"; }

.flaticon.stroke.tag-2:before,
.flaticon.stroke.L5:before { content: "\e4c8"; }

.flaticon.stroke.money-1:before,
.flaticon.stroke.money:before,
.flaticon.stroke.currency-1:before,
.flaticon.stroke.currency:before,
.flaticon.stroke.L6:before { content: "\e4c9"; }

.flaticon.stroke.money-2:before,
.flaticon.stroke.currency-2:before,
.flaticon.stroke.L7:before { content: "\e4ca"; }

.flaticon.stroke.shop-1:before,
.flaticon.stroke.shop:before,
.flaticon.stroke.market-1:before,
.flaticon.stroke.market:before,
.flaticon.stroke.store-1:before,
.flaticon.stroke.store:before,
.flaticon.stroke.L8:before { content: "\e4cb"; }

.flaticon.stroke.shop-2:before,
.flaticon.stroke.market-2:before,
.flaticon.stroke.store-2:before,
.flaticon.stroke.L9:before { content: "\e4cc"; }

.flaticon.stroke.basket-1:before,
.flaticon.stroke.basket:before,
.flaticon.stroke.L10:before { content: "\e4cd"; }

.flaticon.stroke.basket-2:before,
.flaticon.stroke.L11:before { content: "\e4ce"; }

.flaticon.stroke.gift-1:before,
.flaticon.stroke.gift:before,
.flaticon.stroke.present-1:before,
.flaticon.stroke.present:before,
.flaticon.stroke.L12:before { content: "\e4cf"; }

.flaticon.stroke.gift-2:before,
.flaticon.stroke.present-2:before,
.flaticon.stroke.L13:before { content: "\e4d0"; }

.flaticon.stroke.bag-1:before,
.flaticon.stroke.bag:before,
.flaticon.stroke.L14:before { content: "\e4d1"; }

.flaticon.stroke.cube-1:before,
.flaticon.stroke.cube:before,
.flaticon.stroke.L15:before { content: "\e4d2"; }

.flaticon.stroke.ring-1:before,
.flaticon.stroke.ring:before,
.flaticon.stroke.L16:before { content: "\e4d3"; }

.flaticon.stroke.wallet-1:before,
.flaticon.stroke.wallet:before,
.flaticon.stroke.payment-1:before,
.flaticon.stroke.payment:before,
.flaticon.stroke.L17:before { content: "\e4d4"; }

.flaticon.stroke.credit-card-1:before,
.flaticon.stroke.credit-card:before,
.flaticon.stroke.payment-2:before,
.flaticon.stroke.L18:before { content: "\e4d5"; }

.flaticon.stroke.flag-1:before,
.flaticon.stroke.flag:before,
.flaticon.stroke.finish-1:before,
.flaticon.stroke.finish:before,
.flaticon.stroke.L19:before { content: "\e4d6"; }

.flaticon.stroke.flag-2:before,
.flaticon.stroke.finish-2:before,
.flaticon.stroke.L20:before { content: "\e4d7"; }

.flaticon.stroke.battery-full-1:before,
.flaticon.stroke.battery-full:before,
.flaticon.stroke.M1:before { content: "\e4d8"; }

.flaticon.stroke.battery-ok-1:before,
.flaticon.stroke.battery-ok:before,
.flaticon.stroke.M2:before { content: "\e4d9"; }

.flaticon.stroke.battery-low-1:before,
.flaticon.stroke.battery-low:before,
.flaticon.stroke.M3:before { content: "\e4da"; }

.flaticon.stroke.battery-empty-1:before,
.flaticon.stroke.battery-empty:before,
.flaticon.stroke.M4:before { content: "\e4db"; }

.flaticon.stroke.battery-charging-1:before,
.flaticon.stroke.battery-charging:before,
.flaticon.stroke.M5:before { content: "\e4dc"; }

.flaticon.stroke.battery-dead-1:before,
.flaticon.stroke.battery-dead:before,
.flaticon.stroke.M6:before { content: "\e4dd"; }

.flaticon.stroke.battery-full-2:before,
.flaticon.stroke.M7:before { content: "\e4de"; }

.flaticon.stroke.battery-ok-2:before,
.flaticon.stroke.M8:before { content: "\e4df"; }

.flaticon.stroke.battery-low-2:before,
.flaticon.stroke.M9:before { content: "\e4e0"; }

.flaticon.stroke.battery-empty-2:before,
.flaticon.stroke.M10:before { content: "\e4e1"; }

.flaticon.stroke.battery-charging-1:before,
.flaticon.stroke.battery-charging:before,
.flaticon.stroke.M11:before { content: "\e4e2"; }

.flaticon.stroke.battery-charging-2:before,
.flaticon.stroke.lightning-bolt-1:before,
.flaticon.stroke.lightning-bolt:before,
.flaticon.stroke.M12:before { content: "\e4e3"; }

.flaticon.stroke.wifi-1:before,
.flaticon.stroke.wifi:before,
.flaticon.stroke.wireless-1:before,
.flaticon.stroke.wireless:before,
.flaticon.stroke.M13:before { content: "\e4e4"; }

.flaticon.stroke.wifi-rounded-1:before,
.flaticon.stroke.wifi-rounded:before,
.flaticon.stroke.wireless-rounded-1:before,
.flaticon.stroke.wireless-rounded:before,
.flaticon.stroke.M14:before { content: "\e4e5"; }

.flaticon.stroke.wifi-2:before,
.flaticon.stroke.wireless-2:before,
.flaticon.stroke.M15:before { content: "\e4e6"; }

.flaticon.stroke.wifi-rounded-2:before,
.flaticon.stroke.wireless-rounded-2:before,
.flaticon.stroke.M16:before { content: "\e4e7"; }

.flaticon.stroke.wifi-3:before,
.flaticon.stroke.wireless-3:before,
.flaticon.stroke.M17:before { content: "\e4e8"; }

.flaticon.stroke.wifi-rounded-3:before,
.flaticon.stroke.wireless-rounded-3:before,
.flaticon.stroke.M18:before { content: "\e4e9"; }

.flaticon.stroke.graph-1:before,
.flaticon.stroke.graph:before,
.flaticon.stroke.M19:before { content: "\e4ea"; }

.flaticon.stroke.graph-2:before,
.flaticon.stroke.stats-1:before,
.flaticon.stroke.stats:before,
.flaticon.stroke.M20:before { content: "\e4eb"; }

.flaticon.stroke.bluetooth-1:before,
.flaticon.stroke.bluetooth:before,
.flaticon.stroke.N1:before { content: "\e4ec"; }

.flaticon.stroke.history-1:before,
.flaticon.stroke.history:before,
.flaticon.stroke.time-machine-1:before,
.flaticon.stroke.time-machine:before,
.flaticon.stroke.N2:before { content: "\e4ed"; }

.flaticon.stroke.time-1:before,
.flaticon.stroke.time:before,
.flaticon.stroke.clock-1:before,
.flaticon.stroke.clock:before,
.flaticon.stroke.N3:before { content: "\e4ee"; }

.flaticon.stroke.time-2:before,
.flaticon.stroke.watch-1:before,
.flaticon.stroke.watch:before,
.flaticon.stroke.N4:before { content: "\e4ef"; }

.flaticon.stroke.alarm-clock-1:before,
.flaticon.stroke.alarm-clock:before,
.flaticon.stroke.alert-1:before,
.flaticon.stroke.alert:before,
.flaticon.stroke.N5:before { content: "\e4f0"; }

.flaticon.stroke.stopwatch-1:before,
.flaticon.stroke.stopwatch:before,
.flaticon.stroke.N6:before { content: "\e4f1"; }

.flaticon.stroke.compass-1:before,
.flaticon.stroke.compass:before,
.flaticon.stroke.N7:before { content: "\e4f2"; }

.flaticon.stroke.gauge-1:before,
.flaticon.stroke.gauge:before,
.flaticon.stroke.speedometer-1:before,
.flaticon.stroke.speedometer:before,
.flaticon.stroke.N8:before { content: "\e4f3"; }

.flaticon.stroke.gauge-2:before,
.flaticon.stroke.N9:before { content: "\e4f4"; }

.flaticon.stroke.map-1:before,
.flaticon.stroke.map:before,
.flaticon.stroke.location-1:before,
.flaticon.stroke.location:before,
.flaticon.stroke.N10:before { content: "\e4f5"; }

.flaticon.stroke.location-pin-1:before,
.flaticon.stroke.location-pin:before,
.flaticon.stroke.N11:before { content: "\e4f6"; }

.flaticon.stroke.location-pin-2:before,
.flaticon.stroke.N12:before { content: "\e4f7"; }

.flaticon.stroke.location-pin-3:before,
.flaticon.stroke.N13:before { content: "\e4f8"; }

.flaticon.stroke.location-pin-4:before,
.flaticon.stroke.N14:before { content: "\e4f9"; }

.flaticon.stroke.location-pin-5:before,
.flaticon.stroke.N15:before { content: "\e4fa"; }

.flaticon.stroke.location-pin-6:before,
.flaticon.stroke.N16:before { content: "\e4fb"; }

.flaticon.stroke.location-arrow-1:before,
.flaticon.stroke.location-arrow:before,
.flaticon.stroke.N17:before { content: "\e4fc"; }

.flaticon.stroke.globe-1:before,
.flaticon.stroke.globe:before,
.flaticon.stroke.world-1:before,
.flaticon.stroke.world:before,
.flaticon.stroke.N18:before { content: "\e4fd"; }

.flaticon.stroke.aim-1:before,
.flaticon.stroke.aim:before,
.flaticon.stroke.crosshairs-1:before,
.flaticon.stroke.crosshairs:before,
.flaticon.stroke.N19:before { content: "\e4fe"; }

.flaticon.stroke.target-1:before,
.flaticon.stroke.target:before,
.flaticon.stroke.aim-2:before,
.flaticon.stroke.N20:before { content: "\e4ff"; }

.flaticon.stroke.call-1:before,
.flaticon.stroke.call:before,
.flaticon.stroke.phone-1:before,
.flaticon.stroke.phone:before,
.flaticon.stroke.O1:before { content: "\e500"; }

.flaticon.stroke.call-2:before,
.flaticon.stroke.phone-2:before,
.flaticon.stroke.O2:before { content: "\e501"; }

.flaticon.stroke.call-3:before,
.flaticon.stroke.phone-3:before,
.flaticon.stroke.O3:before { content: "\e502"; }

.flaticon.stroke.call-4:before,
.flaticon.stroke.phone-4:before,
.flaticon.stroke.O4:before { content: "\e503"; }

.flaticon.stroke.lightbulb-1:before,
.flaticon.stroke.lightbulb:before,
.flaticon.stroke.idea-1:before,
.flaticon.stroke.idea:before,
.flaticon.stroke.O5:before { content: "\e504"; }

.flaticon.stroke.lightbulb-2:before,
.flaticon.stroke.O6:before { content: "\e505"; }

.flaticon.stroke.lightbulb-3:before,
.flaticon.stroke.O7:before { content: "\e506"; }

.flaticon.stroke.see-all-1:before,
.flaticon.stroke.see-all:before,
.flaticon.stroke.dashboard-1:before,
.flaticon.stroke.dashboard:before,
.flaticon.stroke.O8:before { content: "\e507"; }

.flaticon.stroke.command-1:before,
.flaticon.stroke.command:before,
.flaticon.stroke.O9:before { content: "\e508"; }

.flaticon.stroke.option-1:before,
.flaticon.stroke.option:before,
.flaticon.stroke.O10:before { content: "\e509"; }

.flaticon.stroke.control-1:before,
.flaticon.stroke.control:before,
.flaticon.stroke.O11:before { content: "\e50a"; }

.flaticon.stroke.shift-1:before,
.flaticon.stroke.shift:before,
.flaticon.stroke.O12:before { content: "\e50b"; }

.flaticon.stroke.eject-1:before,
.flaticon.stroke.eject:before,
.flaticon.stroke.O13:before { content: "\e50c"; }

.flaticon.stroke.loading-1:before,
.flaticon.stroke.loading:before,
.flaticon.stroke.O14:before { content: "\e50d"; }

.flaticon.stroke.loading-2:before,
.flaticon.stroke.O15:before { content: "\e50e"; }

.flaticon.stroke.loading-3:before,
.flaticon.stroke.O16:before { content: "\e50f"; }

.flaticon.stroke.logout-1:before,
.flaticon.stroke.logout:before,
.flaticon.stroke.shut-down-1:before,
.flaticon.stroke.shut-down:before,
.flaticon.stroke.O17:before { content: "\e510"; }

.flaticon.stroke.asterisk-1:before,
.flaticon.stroke.asterisk:before,
.flaticon.stroke.O18:before { content: "\e511"; }

.flaticon.stroke.email-1:before,
.flaticon.stroke.email:before,
.flaticon.stroke.at-1:before,
.flaticon.stroke.at:before,
.flaticon.stroke.O19:before { content: "\e512"; }

.flaticon.stroke.pound-1:before,
.flaticon.stroke.pound:before,
.flaticon.stroke.hashtag-1:before,
.flaticon.stroke.hashtag:before,
.flaticon.stroke.O20:before { content: "\e513"; }

.flaticon.stroke.settings-1:before,
.flaticon.stroke.settings:before,
.flaticon.stroke.gear-1:before,
.flaticon.stroke.gear:before,
.flaticon.stroke.P1:before { content: "\e514"; }

.flaticon.stroke.settings-2:before,
.flaticon.stroke.gear-2:before,
.flaticon.stroke.P2:before { content: "\e515"; }

.flaticon.stroke.wrench-1:before,
.flaticon.stroke.wrench:before,
.flaticon.stroke.tool-1:before,
.flaticon.stroke.tool:before,
.flaticon.stroke.P3:before { content: "\e516"; }

.flaticon.stroke.wrench-2:before,
.flaticon.stroke.tool-2:before,
.flaticon.stroke.P4:before { content: "\e517"; }

.flaticon.stroke.work-1:before,
.flaticon.stroke.work:before,
.flaticon.stroke.tool-3:before,
.flaticon.stroke.skill-1:before,
.flaticon.stroke.skill:before,
.flaticon.stroke.P5:before { content: "\e518"; }

.flaticon.stroke.scissors-1:before,
.flaticon.stroke.scissors:before,
.flaticon.stroke.cut-1:before,
.flaticon.stroke.cut:before,
.flaticon.stroke.P6:before { content: "\e519"; }

.flaticon.stroke.type-1:before,
.flaticon.stroke.type:before,
.flaticon.stroke.text-1:before,
.flaticon.stroke.text:before,
.flaticon.stroke.P7:before { content: "\e51a"; }

.flaticon.stroke.graph-3:before,
.flaticon.stroke.stats-2:before,
.flaticon.stroke.P8:before { content: "\e51b"; }

.flaticon.stroke.activity-monitor-1:before,
.flaticon.stroke.activity-monitor:before,
.flaticon.stroke.P9:before { content: "\e51c"; }

.flaticon.stroke.activity-1:before,
.flaticon.stroke.activity:before,
.flaticon.stroke.graph-4:before,
.flaticon.stroke.P10:before { content: "\e51d"; }

.flaticon.stroke.running-1:before,
.flaticon.stroke.running:before,
.flaticon.stroke.P11:before { content: "\e51e"; }

.flaticon.stroke.error-1:before,
.flaticon.stroke.error:before,
.flaticon.stroke.no-1:before,
.flaticon.stroke.no:before,
.flaticon.stroke.P12:before { content: "\e51f"; }

.flaticon.stroke.slider-1:before,
.flaticon.stroke.slider:before,
.flaticon.stroke.P13:before { content: "\e520"; }

.flaticon.stroke.slider-2:before,
.flaticon.stroke.P14:before { content: "\e521"; }

.flaticon.stroke.slider-3:before,
.flaticon.stroke.P15:before { content: "\e522"; }

.flaticon.stroke.toggle-1:before,
.flaticon.stroke.toggle:before,
.flaticon.stroke.P16:before { content: "\e523"; }

.flaticon.stroke.server-1:before,
.flaticon.stroke.server:before,
.flaticon.stroke.P17:before { content: "\e524"; }

.flaticon.stroke.network-1:before,
.flaticon.stroke.network:before,
.flaticon.stroke.P18:before { content: "\e525"; }

.flaticon.stroke.copyright-1:before,
.flaticon.stroke.copyright:before,
.flaticon.stroke.P19:before { content: "\e526"; }

.flaticon.stroke.crop-1:before,
.flaticon.stroke.crop:before,
.flaticon.stroke.P20:before { content: "\e527"; }

.flaticon.stroke.left-text-1:before,
.flaticon.stroke.left-text:before,
.flaticon.stroke.Q1:before { content: "\e528"; }

.flaticon.stroke.center-text-1:before,
.flaticon.stroke.center-text:before,
.flaticon.stroke.Q2:before { content: "\e529"; }

.flaticon.stroke.right-text-1:before,
.flaticon.stroke.right-text:before,
.flaticon.stroke.Q3:before { content: "\e52a"; }

.flaticon.stroke.paragraph-text-1:before,
.flaticon.stroke.paragraph-text:before,
.flaticon.stroke.Q4:before { content: "\e52b"; }

.flaticon.stroke.align-top-1:before,
.flaticon.stroke.align-top:before,
.flaticon.stroke.Q5:before { content: "\e52c"; }

.flaticon.stroke.align-bottom-1:before,
.flaticon.stroke.align-bottom:before,
.flaticon.stroke.Q6:before { content: "\e52d"; }

.flaticon.stroke.align-horizontally-1:before,
.flaticon.stroke.align-horizontally:before,
.flaticon.stroke.Q7:before { content: "\e52e"; }

.flaticon.stroke.align-left-1:before,
.flaticon.stroke.align-left:before,
.flaticon.stroke.Q8:before { content: "\e52f"; }

.flaticon.stroke.align-center-1:before,
.flaticon.stroke.align-center:before,
.flaticon.stroke.Q9:before { content: "\e530"; }

.flaticon.stroke.align-right-1:before,
.flaticon.stroke.align-right:before,
.flaticon.stroke.Q10:before { content: "\e531"; }

.flaticon.stroke.align-top-edges-1:before,
.flaticon.stroke.align-top-edges:before,
.flaticon.stroke.Q11:before { content: "\e532"; }

.flaticon.stroke.distribute-vertical-centers-1:before,
.flaticon.stroke.distribute-vertical-centers:before,
.flaticon.stroke.Q12:before { content: "\e533"; }

.flaticon.stroke.align-bottom-edges-1:before,
.flaticon.stroke.align-bottom-edges:before,
.flaticon.stroke.Q13:before { content: "\e534"; }

.flaticon.stroke.align-left-edges-1:before,
.flaticon.stroke.align-left-edges:before,
.flaticon.stroke.Q14:before { content: "\e535"; }

.flaticon.stroke.distribute-centers-horizontally-1:before,
.flaticon.stroke.distribute-centers-horizontally:before,
.flaticon.stroke.Q15:before { content: "\e536"; }

.flaticon.stroke.align-right-edges-1:before,
.flaticon.stroke.align-right-edges:before,
.flaticon.stroke.Q16:before { content: "\e537"; }

.flaticon.stroke.layout-1:before,
.flaticon.stroke.layout:before,
.flaticon.stroke.Q17:before { content: "\e538"; }

.flaticon.stroke.layout-2:before,
.flaticon.stroke.Q18:before { content: "\e539"; }

.flaticon.stroke.layout-3:before,
.flaticon.stroke.Q19:before { content: "\e53a"; }

.flaticon.stroke.layout-4:before,
.flaticon.stroke.Q20:before { content: "\e53b"; }

.flaticon.stroke.menu-1:before,
.flaticon.stroke.menu:before,
.flaticon.stroke.R1:before { content: "\e53c"; }

.flaticon.stroke.menu-2:before,
.flaticon.stroke.R2:before { content: "\e53d"; }

.flaticon.stroke.menu-3:before,
.flaticon.stroke.R3:before { content: "\e53e"; }

.flaticon.stroke.menu-list-1:before,
.flaticon.stroke.menu-list:before,
.flaticon.stroke.R4:before { content: "\e53f"; }

.flaticon.stroke.menu-list-2:before,
.flaticon.stroke.R5:before { content: "\e540"; }

.flaticon.stroke.menu-list-3:before,
.flaticon.stroke.R6:before { content: "\e541"; }

.flaticon.stroke.menu-list-4:before,
.flaticon.stroke.R7:before { content: "\e542"; }

.flaticon.stroke.paragraph-justify-1:before,
.flaticon.stroke.paragraph-justify:before,
.flaticon.stroke.R8:before { content: "\e543"; }

.flaticon.stroke.paragraph-left-justify-1:before,
.flaticon.stroke.paragraph-left-justify:before,
.flaticon.stroke.R9:before { content: "\e544"; }

.flaticon.stroke.paragraph-left-1:before,
.flaticon.stroke.paragraph-left:before,
.flaticon.stroke.R10:before { content: "\e545"; }

.flaticon.stroke.paragraph-centered-1:before,
.flaticon.stroke.paragraph-centered:before,
.flaticon.stroke.R11:before { content: "\e546"; }

.flaticon.stroke.paragraph-right-1:before,
.flaticon.stroke.paragraph-right:before,
.flaticon.stroke.R12:before { content: "\e547"; }

.flaticon.stroke.paragraph-right-justify-1:before,
.flaticon.stroke.paragraph-right-justify:before,
.flaticon.stroke.R13:before { content: "\e548"; }

.flaticon.stroke.marquee-1:before,
.flaticon.stroke.marquee:before,
.flaticon.stroke.R14:before { content: "\e549"; }

.flaticon.stroke.marquee-plus-1:before,
.flaticon.stroke.marquee-plus:before,
.flaticon.stroke.R15:before { content: "\e54a"; }

.flaticon.stroke.marquee-2:before,
.flaticon.stroke.R16:before { content: "\e54b"; }

.flaticon.stroke.marquee-plus-2:before,
.flaticon.stroke.R17:before { content: "\e54c"; }

.flaticon.stroke.sun-1:before,
.flaticon.stroke.sun:before,
.flaticon.stroke.brightness-1:before,
.flaticon.stroke.brightness:before,
.flaticon.stroke.R18:before { content: "\e54d"; }

.flaticon.stroke.sun-2:before,
.flaticon.stroke.brightness-2:before,
.flaticon.stroke.R19:before { content: "\e54e"; }

.flaticon.stroke.sun-3:before,
.flaticon.stroke.brightness-3:before,
.flaticon.stroke.R20:before { content: "\e54f"; }

.flaticon.stroke.turn-page-down-1:before,
.flaticon.stroke.turn-page-down:before,
.flaticon.stroke.S1:before { content: "\e550"; }

.flaticon.stroke.turn-page-up-1:before,
.flaticon.stroke.turn-page-up:before,
.flaticon.stroke.S2:before { content: "\e551"; }

.flaticon.stroke.slide-out-left-1:before,
.flaticon.stroke.slide-out-left:before,
.flaticon.stroke.S3:before { content: "\e552"; }

.flaticon.stroke.slide-in-right-1:before,
.flaticon.stroke.slide-in-right:before,
.flaticon.stroke.S4:before { content: "\e553"; }

.flaticon.stroke.slide-in-left-1:before,
.flaticon.stroke.slide-in-left:before,
.flaticon.stroke.S5:before { content: "\e554"; }

.flaticon.stroke.slide-out-right-1:before,
.flaticon.stroke.slide-out-right:before,
.flaticon.stroke.S6:before { content: "\e555"; }

.flaticon.stroke.fold-up-1:before,
.flaticon.stroke.fold-up:before,
.flaticon.stroke.S7:before { content: "\e556"; }

.flaticon.stroke.fold-down-1:before,
.flaticon.stroke.fold-down:before,
.flaticon.stroke.S8:before { content: "\e557"; }

.flaticon.stroke.fold-left-1:before,
.flaticon.stroke.fold-left:before,
.flaticon.stroke.S9:before { content: "\e558"; }

.flaticon.stroke.fold-right-1:before,
.flaticon.stroke.fold-right:before,
.flaticon.stroke.S10:before { content: "\e559"; }

.flaticon.stroke.move-up-1:before,
.flaticon.stroke.move-up:before,
.flaticon.stroke.S11:before { content: "\e55a"; }

.flaticon.stroke.move-down-1:before,
.flaticon.stroke.move-down:before,
.flaticon.stroke.S12:before { content: "\e55b"; }

.flaticon.stroke.login-1:before,
.flaticon.stroke.login:before,
.flaticon.stroke.S13:before { content: "\e55c"; }

.flaticon.stroke.logout-2:before,
.flaticon.stroke.S14:before { content: "\e55d"; }

.flaticon.stroke.upload-1:before,
.flaticon.stroke.upload:before,
.flaticon.stroke.S15:before { content: "\e55e"; }

.flaticon.stroke.download-1:before,
.flaticon.stroke.download:before,
.flaticon.stroke.S16:before { content: "\e55f"; }

.flaticon.stroke.upload-2:before,
.flaticon.stroke.S17:before { content: "\e560"; }

.flaticon.stroke.download-2:before,
.flaticon.stroke.S18:before { content: "\e561"; }

.flaticon.stroke.save-1:before,
.flaticon.stroke.save:before,
.flaticon.stroke.S19:before { content: "\e562"; }

.flaticon.stroke.save-2:before,
.flaticon.stroke.S20:before { content: "\e563"; }

.flaticon.stroke.previous-1:before,
.flaticon.stroke.previous:before,
.flaticon.stroke.T1:before { content: "\e564"; }

.flaticon.stroke.rewind-1:before,
.flaticon.stroke.rewind:before,
.flaticon.stroke.T2:before { content: "\e565"; }

.flaticon.stroke.stop-1:before,
.flaticon.stroke.stop:before,
.flaticon.stroke.T3:before { content: "\e566"; }

.flaticon.stroke.pause-1:before,
.flaticon.stroke.pause:before,
.flaticon.stroke.T4:before { content: "\e567"; }

.flaticon.stroke.play-1:before,
.flaticon.stroke.play:before,
.flaticon.stroke.T5:before { content: "\e568"; }

.flaticon.stroke.fast-forward-1:before,
.flaticon.stroke.fast-forward:before,
.flaticon.stroke.T6:before { content: "\e569"; }

.flaticon.stroke.next-1:before,
.flaticon.stroke.next:before,
.flaticon.stroke.T7:before { content: "\e56a"; }

.flaticon.stroke.previous-2:before,
.flaticon.stroke.T8:before { content: "\e56b"; }

.flaticon.stroke.rewind-2:before,
.flaticon.stroke.T9:before { content: "\e56c"; }

.flaticon.stroke.stop-2:before,
.flaticon.stroke.T10:before { content: "\e56d"; }

.flaticon.stroke.pause-2:before,
.flaticon.stroke.T11:before { content: "\e56e"; }

.flaticon.stroke.play-2:before,
.flaticon.stroke.T12:before { content: "\e56f"; }

.flaticon.stroke.fast-forward-2:before,
.flaticon.stroke.T13:before { content: "\e570"; }

.flaticon.stroke.next-2:before,
.flaticon.stroke.T14:before { content: "\e571"; }

.flaticon.stroke.previous-3:before,
.flaticon.stroke.T15:before { content: "\e572"; }

.flaticon.stroke.rewind-3:before,
.flaticon.stroke.T16:before { content: "\e573"; }

.flaticon.stroke.pause-3:before,
.flaticon.stroke.T17:before { content: "\e574"; }

.flaticon.stroke.play-3:before,
.flaticon.stroke.T18:before { content: "\e575"; }

.flaticon.stroke.fast-forward-3:before,
.flaticon.stroke.T19:before { content: "\e576"; }

.flaticon.stroke.next-3:before,
.flaticon.stroke.T20:before { content: "\e577"; }

.flaticon.stroke.previous-4:before,
.flaticon.stroke.U1:before { content: "\e578"; }

.flaticon.stroke.pause-4:before,
.flaticon.stroke.U2:before { content: "\e579"; }

.flaticon.stroke.play-4:before,
.flaticon.stroke.U3:before { content: "\e57a"; }

.flaticon.stroke.next-4:before,
.flaticon.stroke.U4:before { content: "\e57b"; }

.flaticon.stroke.speaker-1:before,
.flaticon.stroke.speaker:before,
.flaticon.stroke.volume-1:before,
.flaticon.stroke.volume:before,
.flaticon.stroke.U5:before { content: "\e57c"; }

.flaticon.stroke.speaker-2:before,
.flaticon.stroke.volume-2:before,
.flaticon.stroke.U6:before { content: "\e57d"; }

.flaticon.stroke.speaker-3:before,
.flaticon.stroke.volume-3:before,
.flaticon.stroke.mute-1:before,
.flaticon.stroke.mute:before,
.flaticon.stroke.U7:before { content: "\e57e"; }

.flaticon.stroke.speaker-4:before,
.flaticon.stroke.volume-4:before,
.flaticon.stroke.U8:before { content: "\e57f"; }

.flaticon.stroke.headphones-1:before,
.flaticon.stroke.headphones:before,
.flaticon.stroke.U9:before { content: "\e580"; }

.flaticon.stroke.microphone-1:before,
.flaticon.stroke.microphone:before,
.flaticon.stroke.U10:before { content: "\e581"; }

.flaticon.stroke.microphone-2:before,
.flaticon.stroke.mute-2:before,
.flaticon.stroke.U11:before { content: "\e582"; }

.flaticon.stroke.music-1:before,
.flaticon.stroke.music:before,
.flaticon.stroke.U12:before { content: "\e583"; }

.flaticon.stroke.megaphone-1:before,
.flaticon.stroke.megaphone:before,
.flaticon.stroke.announcement-1:before,
.flaticon.stroke.announcement:before,
.flaticon.stroke.U13:before { content: "\e584"; }

.flaticon.stroke.infinity-1:before,
.flaticon.stroke.infinity:before,
.flaticon.stroke.loop-1:before,
.flaticon.stroke.loop:before,
.flaticon.stroke.U14:before { content: "\e585"; }

.flaticon.stroke.shuffle-1:before,
.flaticon.stroke.shuffle:before,
.flaticon.stroke.U15:before { content: "\e586"; }

.flaticon.stroke.repeat-1:before,
.flaticon.stroke.repeat:before,
.flaticon.stroke.reload-1:before,
.flaticon.stroke.reload:before,
.flaticon.stroke.U16:before { content: "\e587"; }

.flaticon.stroke.repeat-2:before,
.flaticon.stroke.reload-2:before,
.flaticon.stroke.U17:before { content: "\e588"; }

.flaticon.stroke.repeat-3:before,
.flaticon.stroke.reload-3:before,
.flaticon.stroke.U18:before { content: "\e589"; }

.flaticon.stroke.repeat-4:before,
.flaticon.stroke.reload-4:before,
.flaticon.stroke.U19:before { content: "\e58a"; }

.flaticon.stroke.refresh-1:before,
.flaticon.stroke.refresh:before,
.flaticon.stroke.U20:before { content: "\e58b"; }

.flaticon.stroke.position-1:before,
.flaticon.stroke.position:before,
.flaticon.stroke.move-1:before,
.flaticon.stroke.move:before,
.flaticon.stroke.V1:before { content: "\e58c"; }

.flaticon.stroke.minimize-1:before,
.flaticon.stroke.minimize:before,
.flaticon.stroke.contract-1:before,
.flaticon.stroke.contract:before,
.flaticon.stroke.V2:before { content: "\e58d"; }

.flaticon.stroke.maximize-1:before,
.flaticon.stroke.maximize:before,
.flaticon.stroke.expand-1:before,
.flaticon.stroke.expand:before,
.flaticon.stroke.V3:before { content: "\e58e"; }

.flaticon.stroke.maximize-2:before,
.flaticon.stroke.expand-2:before,
.flaticon.stroke.V4:before { content: "\e58f"; }

.flaticon.stroke.maximize-3:before,
.flaticon.stroke.expand-3:before,
.flaticon.stroke.V5:before { content: "\e590"; }

.flaticon.stroke.maximize-4:before,
.flaticon.stroke.expand-4:before,
.flaticon.stroke.V6:before { content: "\e591"; }

.flaticon.stroke.expand-vertically-1:before,
.flaticon.stroke.expand-vertically:before,
.flaticon.stroke.V7:before { content: "\e592"; }

.flaticon.stroke.expand-horizontally-1:before,
.flaticon.stroke.expand-horizontally:before,
.flaticon.stroke.V8:before { content: "\e593"; }

.flaticon.stroke.arrow-up-1:before,
.flaticon.stroke.arrow-up:before,
.flaticon.stroke.V9:before { content: "\e594"; }

.flaticon.stroke.arrow-down-1:before,
.flaticon.stroke.arrow-down:before,
.flaticon.stroke.V10:before { content: "\e595"; }

.flaticon.stroke.arrow-left-1:before,
.flaticon.stroke.arrow-left:before,
.flaticon.stroke.V11:before { content: "\e596"; }

.flaticon.stroke.arrow-right-1:before,
.flaticon.stroke.arrow-right:before,
.flaticon.stroke.V12:before { content: "\e597"; }

.flaticon.stroke.arrow-up-2:before,
.flaticon.stroke.V13:before { content: "\e598"; }

.flaticon.stroke.arrow-down-2:before,
.flaticon.stroke.V14:before { content: "\e599"; }

.flaticon.stroke.arrow-left-2:before,
.flaticon.stroke.V15:before { content: "\e59a"; }

.flaticon.stroke.arrow-right-2:before,
.flaticon.stroke.V16:before { content: "\e59b"; }

.flaticon.stroke.left-angle-quote-1:before,
.flaticon.stroke.left-angle-quote:before,
.flaticon.stroke.V17:before { content: "\e59c"; }

.flaticon.stroke.right-angle-quote-1:before,
.flaticon.stroke.right-angle-quote:before,
.flaticon.stroke.V18:before { content: "\e59d"; }

.flaticon.stroke.left-angle-quote-2:before,
.flaticon.stroke.V19:before { content: "\e59e"; }

.flaticon.stroke.right-angle-quote-2:before,
.flaticon.stroke.V20:before { content: "\e59f"; }

.flaticon.stroke.plus-1:before,
.flaticon.stroke.plus:before,
.flaticon.stroke.W1:before { content: "\e5a0"; }

.flaticon.stroke.minus-1:before,
.flaticon.stroke.minus:before,
.flaticon.stroke.W2:before { content: "\e5a1"; }

.flaticon.stroke.checkmark-1:before,
.flaticon.stroke.checkmark:before,
.flaticon.stroke.W3:before { content: "\e5a2"; }

.flaticon.stroke.x-1:before,
.flaticon.stroke.x:before,
.flaticon.stroke.W4:before { content: "\e5a3"; }

.flaticon.stroke.up-1:before,
.flaticon.stroke.up:before,
.flaticon.stroke.W5:before { content: "\e5a4"; }

.flaticon.stroke.down-1:before,
.flaticon.stroke.down:before,
.flaticon.stroke.W6:before { content: "\e5a5"; }

.flaticon.stroke.left-1:before,
.flaticon.stroke.left:before,
.flaticon.stroke.W7:before { content: "\e5a6"; }

.flaticon.stroke.right-1:before,
.flaticon.stroke.right:before,
.flaticon.stroke.W8:before { content: "\e5a7"; }

.flaticon.stroke.up-2:before,
.flaticon.stroke.W9:before { content: "\e5a8"; }

.flaticon.stroke.down-2:before,
.flaticon.stroke.W10:before { content: "\e5a9"; }

.flaticon.stroke.left-2:before,
.flaticon.stroke.W11:before { content: "\e5aa"; }

.flaticon.stroke.right-2:before,
.flaticon.stroke.W12:before { content: "\e5ab"; }

.flaticon.stroke.up-3:before,
.flaticon.stroke.W13:before { content: "\e5ac"; }

.flaticon.stroke.down-3:before,
.flaticon.stroke.W14:before { content: "\e5ad"; }

.flaticon.stroke.left-3:before,
.flaticon.stroke.W15:before { content: "\e5ae"; }

.flaticon.stroke.right-3:before,
.flaticon.stroke.W16:before { content: "\e5af"; }

.flaticon.stroke.up-4:before,
.flaticon.stroke.W17:before { content: "\e5b0"; }

.flaticon.stroke.down-4:before,
.flaticon.stroke.W18:before { content: "\e5b1"; }

.flaticon.stroke.left-4:before,
.flaticon.stroke.W19:before { content: "\e5b2"; }

.flaticon.stroke.right-4:before,
.flaticon.stroke.W20:before { content: "\e5b3"; }

.flaticon.stroke.plus-2:before,
.flaticon.stroke.X1:before { content: "\e5b4"; }

.flaticon.stroke.minus-2:before,
.flaticon.stroke.X2:before { content: "\e5b5"; }

.flaticon.stroke.checkmark-2:before,
.flaticon.stroke.X3:before { content: "\e5b6"; }

.flaticon.stroke.x-2:before,
.flaticon.stroke.X4:before { content: "\e5b7"; }

.flaticon.stroke.up-circle-1:before,
.flaticon.stroke.up-circle:before,
.flaticon.stroke.X5:before { content: "\e5b8"; }

.flaticon.stroke.down-circle-1:before,
.flaticon.stroke.down-circle:before,
.flaticon.stroke.X6:before { content: "\e5b9"; }

.flaticon.stroke.left-circle-1:before,
.flaticon.stroke.left-circle:before,
.flaticon.stroke.X7:before { content: "\e5ba"; }

.flaticon.stroke.right-circle-1:before,
.flaticon.stroke.right-circle:before,
.flaticon.stroke.X8:before { content: "\e5bb"; }

.flaticon.stroke.up-circle-2:before,
.flaticon.stroke.X9:before { content: "\e5bc"; }

.flaticon.stroke.down-circle-2:before,
.flaticon.stroke.X10:before { content: "\e5bd"; }

.flaticon.stroke.left-circle-2:before,
.flaticon.stroke.X11:before { content: "\e5be"; }

.flaticon.stroke.right-circle-2:before,
.flaticon.stroke.X12:before { content: "\e5bf"; }

.flaticon.stroke.up-circle-3:before,
.flaticon.stroke.X13:before { content: "\e5c0"; }

.flaticon.stroke.down-circle-3:before,
.flaticon.stroke.download-3:before,
.flaticon.stroke.X14:before { content: "\e5c1"; }

.flaticon.stroke.left-circle-3:before,
.flaticon.stroke.X15:before { content: "\e5c2"; }

.flaticon.stroke.right-circle-3:before,
.flaticon.stroke.X16:before { content: "\e5c3"; }

.flaticon.stroke.up-circle-4:before,
.flaticon.stroke.X17:before { content: "\e5c4"; }

.flaticon.stroke.down-circle-4:before,
.flaticon.stroke.X18:before { content: "\e5c5"; }

.flaticon.stroke.left-circle-4:before,
.flaticon.stroke.X19:before { content: "\e5c6"; }

.flaticon.stroke.right-circle-4:before,
.flaticon.stroke.X20:before { content: "\e5c7"; }

.flaticon.stroke.plus-3:before,
.flaticon.stroke.Y1:before { content: "\e5c8"; }

.flaticon.stroke.minus-3:before,
.flaticon.stroke.Y2:before { content: "\e5c9"; }

.flaticon.stroke.checkmark-3:before,
.flaticon.stroke.Y3:before { content: "\e5ca"; }

.flaticon.stroke.x-3:before,
.flaticon.stroke.Y4:before { content: "\e5cb"; }

.flaticon.stroke.up-square-1:before,
.flaticon.stroke.up-square:before,
.flaticon.stroke.Y5:before { content: "\e5cc"; }

.flaticon.stroke.down-square-1:before,
.flaticon.stroke.down-square:before,
.flaticon.stroke.Y6:before { content: "\e5cd"; }

.flaticon.stroke.left-square-1:before,
.flaticon.stroke.left-square:before,
.flaticon.stroke.Y7:before { content: "\e5ce"; }

.flaticon.stroke.right-square-1:before,
.flaticon.stroke.right-square:before,
.flaticon.stroke.Y8:before { content: "\e5cf"; }

.flaticon.stroke.up-square-2:before,
.flaticon.stroke.Y9:before { content: "\e5d0"; }

.flaticon.stroke.down-square-2:before,
.flaticon.stroke.Y10:before { content: "\e5d1"; }

.flaticon.stroke.left-square-2:before,
.flaticon.stroke.Y11:before { content: "\e5d2"; }

.flaticon.stroke.right-square-2:before,
.flaticon.stroke.Y12:before { content: "\e5d3"; }

.flaticon.stroke.up-square-3:before,
.flaticon.stroke.Y13:before { content: "\e5d4"; }

.flaticon.stroke.down-square-3:before,
.flaticon.stroke.download-4:before,
.flaticon.stroke.Y14:before { content: "\e5d5"; }

.flaticon.stroke.left-square-3:before,
.flaticon.stroke.Y15:before { content: "\e5d6"; }

.flaticon.stroke.right-square-3:before,
.flaticon.stroke.Y16:before { content: "\e5d7"; }

.flaticon.stroke.up-square-4:before,
.flaticon.stroke.Y17:before { content: "\e5d8"; }

.flaticon.stroke.down-square-4:before,
.flaticon.stroke.Y18:before { content: "\e5d9"; }

.flaticon.stroke.left-square-4:before,
.flaticon.stroke.Y19:before { content: "\e5da"; }

.flaticon.stroke.right-square-4:before,
.flaticon.stroke.Y20:before { content: "\e5db"; }

