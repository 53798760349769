//
// Tables
// --------------------------------------------------

// Baseline styles

.table {
  font-size: ceil(($component-font-size-base * 0.933)); // ~14px
  margin-bottom: $line-height-computed;

  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: 9px;
        line-height: 1.407; // ~19.7px
        border-top: 2px solid $table-border-color;

        @media (min-width: $screen-sm-min) {
          padding: 9px 25px;
        }
      }
      > th {
        color: mix($brand-primary, white, 85%);
        border-bottom: 2px solid $table-border-color;
      }
    }
  }
  > thead > tr > th {
    border-bottom: 2px solid $table-border-color;
  }
  // Account for multiple tbody instances
  > tbody + tbody {
    border-top: 2px solid $table-border-color;
  }
  // Nesting
  .table {
    background-color: $body-bg;
  }
  [class*="fui-"] {
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
    margin: -42.5px 0 0;
    position: relative;
    top: 1em;
  }
  .checkbox,
  .radio {
    display: inline-block;
    width: 20px;
    margin: -4px 0 0 -9px;
    top: 0.25em;

    &.no-label {
      padding-left: 0;
      margin-right: -9px;
    }
  }
  .selected-row {
    > td {
      background-color: mix($brand-secondary, white, 5.5%);
    }
  }
  img { vertical-align: top; }
}

// Condensed table w/ half padding

.table-condensed {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: 5px 14px;
      }
    }
  }
}

// Bordered version
//
// Add borders all around the table and between all the columns.

.table-bordered {
  border: 2px solid $table-border-color;

  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: 2px solid $table-border-color;
        padding-top: 8px;
        padding-bottom: 8px;
        vertical-align: middle;
      }
    }
  }
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
  > tbody {
    > tr {
      &:nth-child(odd),
      &.selected-row {
        > td,
        > th {
          background-color: $table-bg-accent;
        }
      }
      &:nth-child(even) {
        > td,
        > th {
          background-color: transparent;
        }
      }
    }
  }
}

// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
  > tbody > tr:hover {
    > td,
    > th {
      background-color: $table-bg-hover;
    }
  }
}

// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

// Generate the contextual variants
// @include table-row-variant(active; $table-bg-active);
// @include table-row-variant(success; $table-bg-success);
// @include table-row-variant(info; $table-bg-info);
// @include table-row-variant(warning; $table-bg-warning);
// @include table-row-variant(danger; $table-bg-danger);


// Responsive tables
//
// Wrap your tables in `.table-responsive` and we'll make them mobile friendly
// by enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.

.table-responsive {
  @media screen and (max-width: $screen-xs-max) {
    margin-bottom: ($line-height-computed * 0.75);
    border: 2px solid $table-border-color;

    // Special overrides for the bordered tables
    > .table-bordered {
      border: 0;

      // Nuke the appropriate borders so that the parent can handle them
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th:first-child,
          > td:first-child {
            border-left: 0;
          }
          > th:last-child,
          > td:last-child {
            border-right: 0;
          }
        }
      }

      // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
      // chances are there will be only one `tr` in a `thead` and that would
      // remove the border altogether.
      > tbody,
      > tfoot {
        > tr:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }
    }
  }
}



.table-scrollable {
  tbody {
    display: block;
    max-height: 500px;
    overflow: auto;

    & > tr {
      display: table;
      table-layout: fixed;
      width: 100%;
    }
  }
}



.table-flat {
  tbody > tr > td {
    border: none;
  }
}