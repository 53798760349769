//
// Select
// --------------------------------------------------


// Select container
.select {
  position: relative;
  display: inline-block;
  vertical-align: top;
  min-width: 220px;
  width: auto;

  .form-group & {
    width: 100%;

    > .select2-choice {
      width: 100%;
    }
  }
  &.form-control {
    border: none;
    padding: 0;
    height: auto;
  }
}

// Select wrapper
.select2-choice {
  width: 100%;
  display: inline-block;
  position: relative;
  border: none;
  font-size: $select-font-size-base;
  font-weight: $select-font-weight;
  line-height: $select-line-height-base;
  border-radius: $border-radius-base;
  padding: 10px 39px 10px 15px;
  transition: border .25s linear, color .25s linear, background-color .25s linear;

  &:hover,
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
    box-shadow: none;
  }
  .select2-container-disabled & {
    opacity: 0.7;
  }
}

// Select label
.select2-chosen {
  overflow: hidden;
  text-align: left;
}

// Select arrow
.select2-arrow {
  display: inline-block;
  border-width: 8px 6px;
  border-color: $select-arrow-color transparent;
  border-style: solid;
  border-bottom-style: none;
  position: absolute;
  right: 16px;
  top: 42%;
  transform: scale(1.001);

  b {
    display: none;
  }

  .btn-lg & {
    border-top-width: 8px;
    border-right-width: 6px;
    border-left-width: 6px;
  }
}

// Alternate color variants
// .select-default {
//   @include select-variant($select-default-color, $select-default-bg, $select-default-hover-bg, $select-default-active-bg, $arrow-color: $inverse);
// }
// .select-primary {
//   @include select-variant($select-default-color, $brand-secondary, $select-primary-hover-bg, $select-primary-active-bg, $arrow-color: $inverse);
// }
// .select-info {
//   @include select-variant($select-default-color, $brand-info, $select-info-hover-bg, $select-info-active-bg, $arrow-color: $inverse);
// }
// .select-danger {
//   @include select-variant($select-default-color, $brand-danger, $select-danger-hover-bg, $select-danger-active-bg, $arrow-color: $inverse);
// }
// .select-success {
//   @include select-variant($select-default-color, $brand-success, $select-success-hover-bg, $select-success-active-bg, $arrow-color: $inverse);
// }
// .select-warning {
//   @include select-variant($select-default-color, $brand-warning, $select-warning-hover-bg, $select-warning-active-bg, $arrow-color: $inverse);
// }
// .select-inverse {
//   @include select-variant($select-default-color, $brand-primary, $select-inverse-hover-bg, $select-inverse-active-bg, $arrow-color: $inverse);
// }

// Select sizes
.select-hg {
  .select2-container {
    > .select2-choice {
      // .button-size(@padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius);
      // @include select-size(13px, 20px, $select-font-size-hg, $select-line-height-hg, $border-radius-large);


      .filter-option {
        left: 20px;
        right: 40px;
        top: 13px;
      }
      .select2-arrow {
        right: 20px;
      }
      > [class^="fui-"] {
        top: 2px;
      }
    }
  }
}
.select-lg {
  .select2-container {
    // > .select2-choice {
    //   @include select-size(10px, 19px, $select-font-size-lg, $select-line-height-lg, $border-radius-large);

    //   .filter-option {
    //     left: 18px;
    //     right: 38px;
    //   }
    // }
  }
}
.select-sm {
  .select2-container {
    > .select2-choice {
      // @include select-size(9px, 13px, $select-font-size-sm, $select-line-height-sm, $border-radius-base);

      .filter-option {
        left: 13px;
        right: 33px;
      }
      .select2-arrow {
        right: 13px;
      }
    }
  }
}


// Multiselect
//
// ##

// Multiselect tags wrapper
.select2-choices {
  margin: 0;
  padding: 0;
  position: relative;
  cursor: text;
  overflow: hidden;
  min-height: 26px;
  // &:extend(.clearfix all);

  li {
    float: left;
    list-style: none;
  }
}

// Multiselect tag
.select2-search-choice {
  border-radius: $multiselect-tag-border-radius;
  color: $multiselect-tag-color;
  font-size: floor(($component-font-size-base * 0.886)); // ~13px
  cursor: pointer;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  overflow: hidden;
  margin: 0 5px 4px 0;
  line-height: 15px;
  height: 27px;
  padding: 6px 21px;
  transition: .25s linear;

  &:hover {
    padding-right: 28px;
    padding-left: 14px;
    color: $multiselect-tag-hover-color;

    .select2-search-choice-close {
      opacity: 1;
      filter: none;
      color: inherit;
    }
  }

  // Tag close icon
  .select2-search-choice-close {
    color: $multiselect-tag-icon-color;
    cursor: pointer;
    font-size: ceil(($component-font-size-base * 0.8)); // ~12px;
    position: absolute;
    right: 0;
    text-align: right;
    text-decoration: none;
    top: 0;
    width: 100%;
    bottom: 0;
    padding-right: 10px;
    z-index: 2;
    // @include opacity(0);
    transition: opacity .25s linear;

    &:after {
      content: "\e609";
      font-family: "Flat-UI-Pro-Icons";
      line-height: 27px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}

// Multiselect search field
.select2-search-field {
  input[type="text"] {
    color: $multiselect-input-color;
    font-size: ceil(($component-font-size-base * 0.933)); // 14px;
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    width: auto;
    max-width: inherit;
    min-width: 80px;
    vertical-align: top;
    height: 29px;
  }
  &:first-child input[type="text"] {
    height: 23px;
    margin: 3px 0 5px;
  }
}

// Multiselect variants
// .multiselect-default {
//   @include multiple-select-variant($select-default-bg, $select-default-hover-bg, $select-default-bg);
// }
// .multiselect-primary {
//   @include multiple-select-variant($brand-secondary, $select-primary-hover-bg, $brand-secondary);
// }
// .multiselect-info {
//   @include multiple-select-variant($brand-info, $select-info-hover-bg, $brand-info);
// }
// .multiselect-danger {
//   @include multiple-select-variant($brand-danger, $select-danger-hover-bg, $brand-danger);
// }
// .multiselect-success {
//   @include multiple-select-variant($brand-success, $select-success-hover-bg, $brand-success);
// }
// .multiselect-warning {
//   @include multiple-select-variant($brand-warning, $select-warning-hover-bg, $brand-warning);
// }
// .multiselect-inverse {
//   @include multiple-select-variant($brand-primary, $select-inverse-hover-bg, $brand-primary);
// }


// Select dropdown
//
// ##

// Dropdown container
.select2-drop {
  // &:extend(.dropdown-menu);
  //width: 450px;
  //margin-top: 9px;
  //visibility: visible;
  //opacity: 1;
  //filter: none;
  //font-size: 14px;
  //position: absolute;
  //z-index: 9999;
  //top: 100%;
  //transition: none;
  //
  //&.select2-drop-above {
  //  margin-top: -9px;
  //}
  //&.select2-drop-auto-width {
  //  width: auto;
  //}
  //&.show-select-search .select2-search {
  //  display: block;
  //
  //  + .select2-results {
  //    > li:first-child .select2-result-label {
  //      border-radius: 0;
  //    }
  //  }
  //}

  // Result list
  .select2-results {
    padding: 0;
    margin: 0;
    list-style: none;

    > li:first-child > .select2-result-label {
      // @include border-top-radius($select-dropdown-border-radius);
    }
    > li:last-child > .select2-result-label {
      // @include border-bottom-radius($select-dropdown-border-radius);
    }
  }
  .select2-result-sub {
    padding: 0;
    margin: 0;
    list-style: none;

    > li:last-child > .select2-result-label {
      // @include border-bottom-radius($select-dropdown-border-radius);
    }
  }
  // No results title
  .select2-no-results {
    padding: 8px 15px;
  }
  // Result list item
  .select2-result-label {
    line-height: 1.429; // ~20px
    padding: 8px 16px;
    user-select: none;
    transition: background-color .25s, color;
  }
  // ## Styles for lists without optgroups
  .select2-result-selectable {
    .select2-result-label {
      color: $select-dropdown-item-color;
      cursor: pointer;

      &:focus,
      &:hover,
      &:active {
        background-color: $select-dropdown-item-hover-bg;
        color: $select-dropdown-item-hover-color;
        outline: none;
      }
    }
  }
  // Disabled item
  .select2-disabled {
    cursor: default;
    color: $select-dropdown-disabled-item-color;
    // @include opacity($select-dropdown-disabled-item-opacity);

    &:focus,
    &:hover,
    &:active {
      background: none !important;
    }
  }
  // Highlighted item
  .select2-highlighted {
    > .select2-result-label {
      background: $select-dropdown-highlighted-item-bg;
      color: $select-dropdown-highlighted-item-color;
    }
  }
  // ## Styles for lists with optgroups
  .select2-result-with-children {
    // Optgroup title
    > .select2-result-label {
      font-size: floor(($component-font-size-base * 0.867)); // 13px
      text-transform: uppercase;
      color: $select-dropdown-optgroup-color;
      margin-top: 5px;
    }
    + .select2-result-with-children {
      > .select2-result-label {
        margin-top: 11px;
      }
    }
  }
}

// Dropdown wrapper
.select2-results {
  max-height: 200px;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

// Dropdown search field
.select2-search {
  padding: 8px 6px;
  width: 100%;
  display: none;

  input[type="text"] {
    // &:extend(.form-control all, .input-sm all);
    width: 100%;
    height: auto !important;
  }
}

// Dropdown inverse variant
.select-inverse-dropdown {
  background-color: $brand-primary;
  color: fade($inverse, 75%);

  // Result list
  .select2-results {
    .select2-result-label {
      color: $inverse;

      &:focus,
      &:hover,
      &:active {
        background: mix($brand-primary, black, 85%);
      }
    }
    &.select2-disabled .select2-result-label:hover {
      color: $inverse;
    }
  }
  // Optgroup result list
  .select2-result-with-children {

    // Optgroup title
    > .select2-result-label {
      color: fade($inverse, 60%);

      &:hover {
        color: $inverse;
        background: none !important;
      }
    }
  }
}

// Multiselect dropdown wrapper
.select2-drop-multi {
  border-radius: $multiselect-dropdown-border-radius;

  .select2-results {
    padding: 2px 0;
  }
  .select2-result {
    padding: 2px 4px;
  }
  .select2-result-label {
    border-radius: $multiselect-dropdown-item-border-radius;
  }
  .select2-selected {
    display: none;
  }
}


// Helpers
//
// ##

.select2-offscreen,
.select2-offscreen:focus {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0 !important;
  top: 0 !important;
}

.select2-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.select2-offscreen,
.select2-offscreen:focus {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0 !important;
  top: 0 !important;
}

.select2-display-none {
  display: none;
}

.select2-measure-scrollbar {
  position: absolute;
  top: -10000px;
  left: -10000px;
  width: 100px;
  height: 100px;
  overflow: scroll;
}

.select2-drop-mask {
  border: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  z-index: 9998;
  /* styles required for IE to work */
  background-color: #fff;
  // @include opacity(0);
}
