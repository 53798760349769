@import "mixins";
//
// Variables
// --------------------------------------------------


// == Colors
//
//##

// Color swatches
$turquoise:                 #1abc9c;
$green-sea:                 #16a085;

$emerald:                   #2ecc71;
$nephritis:                 #27ae60;

$peter-river:               #3498db;
$belize-hole:               #2980b9;

$amethyst:                  #9b59b6;
$wisteria:                  #8e44ad;

$wet-asphalt:               #34495e;
$midnight-blue:             #2c3e50;

$sun-flower:                #f1c40f;
$orange:                    #f39c12;

$carrot:                    #e67e22;
$pumpkin:                   #d35400;

$alizarin:                  #e74c3c;
$pomegranate:               #c0392b;

$clouds:                    #ecf0f1;
$silver:                    #bdc3c7;

$concrete:                  #95a5a6;
$asbestos:                  #7f8c8d;

// Grays
$gray:                      $concrete;
$gray-light:                $silver;
$inverse:                   white;

// Brand colors
$brand-primary:             #264463;
$brand-secondary:           #16d6d8;
$brand-success:             #30d98b;
$brand-warning:             #e1ca4c;
$brand-danger:              #f14c4c;
$brand-info:                #16d6da;

// Social colors
$social-pinterest:          #cb2028;
$social-linkedin:           #0072b5;
$social-stumbleupon:        #ed4a13;
$social-googleplus:         #2d2d2d;
$social-facebook:           #2f4b93;
$social-twitter:            #00bdef;


//== Scaffolding
//
//## Settings for some of the most global styles.

$body-bg:                   #fff;
$text-color:                $brand-primary;

//** Global textual link color.
$link-color:                $green-sea;
$link-hover-color:          $turquoise;


//== Typography
//
//## Font, line-height for body text, headings, and more.

$font-family-base:          "Lato", Helvetica, Arial, sans-serif;
$font-family-demo:          "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-monospace:     Monaco, Menlo, Consolas, "Courier New", monospace;
$font-size-base:            18px;

$local-font-path:               "../fonts/lato/";
$local-font-name:               "lato-regular";
$local-font-svg-id:             "latoregular";
$local-font-name-light:         "lato-light";
$local-font-svg-id-light:       "latolight";
$local-font-name-black:         "lato-black";
$local-font-svg-id-black:       "latoblack";
$local-font-name-bold:          "lato-bold";
$local-font-svg-id-bold:        "latobold";
$local-font-name-italic:        "lato-italic";
$local-font-svg-id-italic:      "latoitalic";
$local-font-name-bold-italic:   "lato-bolditalic";
$local-font-svg-id-bold-italic: "latobold-italic";

$font-size-h1:              floor(($font-size-base * 3.444)); // ~62px
$font-size-h2:              ceil(($font-size-base * 2.889)); // ~52px
$font-size-h3:              ceil(($font-size-base * 2.222)); // ~40px
$font-size-h4:              ceil(($font-size-base * 1.611)); // ~29px
$font-size-h5:              floor(($font-size-base * 1.556)); // ~28px
$font-size-h6:              ceil(($font-size-base * 1.333)); // ~24px

$line-height-base:          1.72222; // 31/18
$line-height-computed:      floor(($font-size-base * $line-height-base)); // ~31px

$headings-font-family:      inherit;
$headings-font-weight:      700;
$headings-line-height:      1.1;
$headings-color:            inherit;


//== Iconography
//
//## Specify custom locations of the include Glyphicons icon font.

$icon-font-path:            "../fonts/glyphicons/";
$icon-font-name:            "flat-ui-pro-icons-regular";
$icon-font-svg-id:          "flat-ui-pro-icons-regular";

//** Icon sizes for use in components
$icon-normal:               16px;
$icon-medium:               18px;
$icon-large:                32px;


//== Components
//
//## Define common padding and border radius sizes and more.

//** Default font-size in components
$component-font-size-base:  ceil(($font-size-base * 0.833)); // ~15px

// Border-radius
$border-radius-base:        4px;
$border-radius-large:       6px;
$border-radius-small:       3px;

//** Width of the `border` for generating carets that indicator dropdowns.
$caret-width-base:          6px;
$caret-width-base-vertical: ($caret-width-base + 2);

$caret-width-xs:            4px;
$caret-width-xs-vertical:   ($caret-width-xs + 2);

// Resposive
$rwd-container-space:       20px;


//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Default background color used for all tables.
$table-bg:                      transparent;

//** Background color used for `.table-striped`.
$table-bg-accent:           lighten($brand-primary, 69.4%);

//** Background color used for `.table-hover`.
$table-bg-hover:            lighten($brand-primary, 69.4%);

//** Contextual backgrounds
$table-bg-active:              $table-bg-hover;
$table-bg-success:             mix($brand-success, white, 25%);
$table-bg-danger:              mix($brand-danger, white, 25%);
$table-bg-warning:             mix($brand-warning, white, 25%);
$table-bg-info:                mix($brand-info, white, 25%);

//** Border color for table and cell borders.
$table-border-color:        mix($gray, white, 16.5%);


////== Data Tables
////
////#
//
//$datatables-select-width:            75px;
//$datatables-filter-width:            16em;
//$datatables-disabled-color:          mix($brand-primary, white, 20%);
//$datatables-tr-active:               $brand-primary;
//$datatables-tr-active-hover:         mix($brand-primary, black, 90%);
//$datatables-tr-active-color:         white;
//
//
//
////== Buttons
////
////## For each of Flat UI's buttons, define text, background, font size and height.

$btn-font-size-base:         $component-font-size-base;
$btn-font-size-xs:           ceil(($component-font-size-base * 0.8));   // ~12px
$btn-font-size-sm:           floor(($component-font-size-base * 0.867)); // ~13px
$btn-font-size-lg:           ceil(($component-font-size-base * 1.133));  // ~17px
$btn-font-size-hg:           floor(($component-font-size-base * 1.467)); // ~22px

$btn-line-height-base:       1.4;   // ~21px
$btn-line-height-hg:         1.227; // ~27px
$btn-line-height-lg:         1.471; // ~25px
$btn-line-height-sm:         1.385; // ~16px
$btn-line-height-xs:         1.083; // ~13px

$btn-social-font-size-base:   floor(($component-font-size-base * 0.867)); // ~13px
$btn-social-line-height-base: 1.077;  // ~14px

$btn-font-weight:            normal;

$btn-default-color:          $inverse;
$btn-default-bg:             $gray-light;
$btn-hover-bg:               mix($gray-light, white, 80%);
$btn-active-bg:              mix($gray-light, black, 85%);

$btn-primary-hover-bg:       mix($brand-secondary, white, 80%);
$btn-primary-active-bg:      mix($brand-secondary, black, 85%);

$btn-info-hover-bg:          mix($brand-info, white, 80%);
$btn-info-active-bg:         mix($brand-info, black, 85%);

$btn-success-hover-bg:       mix($brand-success, white, 80%);
$btn-success-active-bg:      mix($brand-success, black, 85%);

$btn-danger-hover-bg:        mix($brand-danger, white, 80%);
$btn-danger-active-bg:       mix($brand-danger, black, 85%);

$btn-warning-hover-bg:       overlay($brand-warning, darken(white, 37.5%));
$btn-warning-active-bg:      mix($brand-warning, black, 85%);

$btn-inverse-hover-bg:       overlay($brand-primary, darken(white, 37.5%));
$btn-inverse-active-bg:      mix($brand-primary, black, 85%);

$btn-link-disabled-color:    $gray-light;
//
////== Forms
//
//##

$input-font-size-base:     $component-font-size-base;
$input-font-size-sm:       floor(($component-font-size-base * 0.867)); // ~13px
$input-font-size-lg:       ceil(($component-font-size-base * 1.133)); // ~17px
$input-font-size-hg:       floor(($component-font-size-base * 1.467)); // ~22px

$input-line-height-base:   1.467; // ~22px
$input-line-height-sm:     1.462; // ~19px
$input-line-height-lg:     1.235; // ~21px
$input-line-height-hg:     1.318; // ~29px

$input-icon-font-size:     ceil(($component-font-size-base * 1.067));  // ~16px
$input-icon-font-size-lg:  ceil(($component-font-size-base * 1.2));  // ~18px
$input-icon-font-size-hg:  ceil(($component-font-size-base * 1.333));  // ~20px

$input-bg:                 $inverse;
$input-bg-disabled:        mix($gray, white, 10%);

$input-height-sm:          35px;
$input-height-base:        41px;
$input-height-lg:          45px;
$input-height-hg:          53px;

$input-border-radius:      $border-radius-large;

$legend-color:             inherit;

////== File input
////
////##
//
//$fileinput-warning-text:     $state-warning-text;
//$fileinput-warning-border:   $state-warning-border;
//$fileinput-danger-text:      $state-danger-text;
//$fileinput-danger-border:    $state-danger-border;
//$fileinput-success-text:     $state-success-text;
//$fileinput-success-border:   $state-success-border;
//
//
////== Datepicker
////
////##
//
//$datepicker-color:           $brand-primary;
//$datepicker-nav-color:       $inverse;
//$datepicker-main:            $brand-secondary;
//$datepicker-bg:              $inverse;
//$datepicker-header-bg:       $datepicker-main;
//$datepicker-border:          $datepicker-main;
//$datepicker-border-radius:   $border-radius-large;
//
//
////== Timepicker
////
////##
//
//$timepicker-nav-color:        mix($brand-secondary, white, 70%);
//$timepicker-nav-hover-color:  $inverse;
//$timepicker-border-radius:    $border-radius-large;
//$timepicker-bg:               $brand-secondary;
//
////== Spinner
////
////##
//
//$adjust-huener-input-width:        55px;
//$adjust-huener-input-font-size:    ceil(($component-font-size-base * 1.333)); // ~20px
//$adjust-huener-input-line-height:  1.3; // ~26px
//
//$adjust-huener-input-placeholder:  desaturate(lighten($brand-primary, 22%), 16%);
//
//$adjust-huener-btn-bg:             $btn-default-bg;
//$adjust-huener-up-btn-border:      mix($gray-light, white, 70%);
//
//$adjust-huener-btn-hover-bg:       mix($gray-light, white, 70%);
//$adjust-huener-btn-hover-color:    mix($brand-secondary, white, 10%);
//
//$adjust-huener-input-focus-color:  $brand-secondary;
//$adjust-huener-input-focus-border: $brand-secondary;
//$adjust-huener-btn-focus-bg:       $brand-secondary;
//$adjust-huener-btn-focus-hover-bg:  mix($brand-secondary, black, 85%);
//$adjust-huener-up-btn-focus-border: mix($brand-secondary, black, 85%);
//
//
////== Tags Input
////
////##
//
$tagsinput-container-bg:                    $inverse;
$tagsinput-container-border-color:          mix($inverse, $brand-primary, 90%);;
$tagsinput-container-border-radius:         $border-radius-large;

$tagsinput-input-color:                     $brand-primary;

$tagsinput-tag-bg:                          mix($inverse, $brand-primary, 90%);;
$tagsinput-tag-color:                       mix($brand-primary, $inverse, 65%);
$tagsinput-tag-hover-bg:                    mix($brand-secondary, black, 85%);
$tagsinput-tag-hover-color:                 $inverse;
$tagsinput-tag-icon-color:                  $inverse;
$tagsinput-tag-border-radius:               $border-radius-base;

$tagsinput-primary-container-border-color:  $brand-secondary;
$tagsinput-primary-tag-bg:                  $brand-secondary;
$tagsinput-primary-tag-color:               $inverse;
$tagsinput-primary-tag-hover-bg:            mix($brand-secondary, black, 85%);
$tagsinput-primary-tag-hover-color:         $inverse;
//
//
//== Selects
//
//## For each of Flat UI's selects, define text, background, font size and height.

$select-font-size-base:                     $btn-font-size-base;
$select-font-size-sm:                       $btn-font-size-sm;
$select-font-size-lg:                       $btn-font-size-lg;
$select-font-size-hg:                       $btn-font-size-hg;

$select-line-height-base:                   $btn-line-height-base;
$select-line-height-hg:                     $btn-line-height-hg;
$select-line-height-lg:                     $btn-line-height-lg;
$select-line-height-sm:                     $btn-line-height-sm;

$select-font-weight:                        $btn-font-weight;

$select-disabled-opacity:                   0.7;

$select-default-color:                      $btn-default-color;
$select-default-bg:                         $btn-default-bg;
$select-default-hover-bg:                   $btn-hover-bg;
$select-default-active-bg:                  $btn-active-bg;

$select-primary-hover-bg:                   $btn-primary-hover-bg;
$select-primary-active-bg:                  $btn-primary-active-bg;

$select-info-hover-bg:                      $btn-info-hover-bg;
$select-info-active-bg:                     $btn-info-active-bg;

$select-success-hover-bg:                   $btn-success-hover-bg;
$select-success-active-bg:                  $btn-success-active-bg;

$select-danger-hover-bg:                    $btn-danger-hover-bg;
$select-danger-active-bg:                   $btn-danger-active-bg;

$select-warning-hover-bg:                   $btn-warning-hover-bg;
$select-warning-active-bg:                  $btn-warning-active-bg;

$select-inverse-hover-bg:                   $btn-inverse-hover-bg;
$select-inverse-active-bg:                  $btn-inverse-active-bg;

$select-link-disabled-color:                $btn-link-disabled-color;
$select-arrow-color:                        $brand-primary;

// Select dropdowns
$select-dropdown-border-radius:             $border-radius-base;

$select-dropdown-item-color:                fade($brand-primary, 85%);
$select-dropdown-item-hover-color:          inherit;
$select-dropdown-item-hover-bg:             mix($inverse, $brand-primary, 85%);

$select-dropdown-disabled-item-color:       fade($brand-primary, 95%);
$select-dropdown-disabled-item-opacity:     0.4;

$select-dropdown-highlighted-item-bg:       $brand-secondary;
$select-dropdown-highlighted-item-color:    $inverse;

$select-dropdown-optgroup-color:            fade($brand-primary, 60%);

// Multiselect
$multiselect-container-bg:                  $tagsinput-container-bg;
$multiselect-container-border-color:        $tagsinput-container-border-color;
$multiselect-container-border-radius:       $tagsinput-container-border-radius;

$multiselect-tag-border-radius:             $tagsinput-tag-border-radius;
$multiselect-tag-color:                     $inverse;
$multiselect-tag-hover-color:               $tagsinput-tag-hover-color;
$multiselect-tag-icon-color:                $tagsinput-tag-icon-color;

$multiselect-dropdown-border-radius:        $border-radius-large;
$multiselect-dropdown-item-border-radius:   $border-radius-base;

$multiselect-input-color:                   $tagsinput-input-color;


//== Pagination
//
//##

$pagination-bg:              mix($brand-primary, white, 20%);
$pagination-hover-bg:        $brand-secondary;
$pagination-color:           $inverse;
$pagination-border-radius:   $border-radius-large;


//== Pager
//
//##

$pager-padding:              9px 15px 10px;
$pager-bg:                   $brand-primary;
$pager-hover-bg:             mix($brand-primary, black, 85%);
$pager-active-bg:            $pager-hover-bg;
$pager-border-radius:        $border-radius-large;
$pager-color:                $inverse;


//== Navs

//##

$nav-tabs-border-width:      2px;
$nav-tabs-border-color:      mix($brand-primary, white, 15%);
$nav-tabs-color:             mix($brand-primary, white, 63%);
$nav-tabs-active-color:      mix($brand-primary, white, 63%);
$nav-tabs-hover-color:       $brand-secondary;
$nav-border-radius:          6px;
$nav-item-border-radius:     ($nav-border-radius - $nav-tabs-border-width);
$nav-open-zindex:            1001;
$nav-list-gap:               13px;

////Nav lists
$nav-list-bg:                mix($brand-primary, white, 9%);
$nav-list-active-bg:         $brand-secondary;
$nav-list-header-color:      mix($brand-primary, white, 40%);
$nav-list-color:             mix($brand-primary, white, 85%);
$nav-list-color-inverse:     $inverse;
$nav-list-hover-color:       mix($brand-secondary, black, 85%);

////== Navbar
////
////##
//
//// Basics of a navbar
$zindex-navbar:              1000;
$zindex-navbar-fixed:        1030;
$navbar-height-base:         53px;
$navbar-height-large:        76px;
$navbar-input-line-height:   1.4; // ~21px
$navbar-margin-bottom:       $line-height-computed;
$navbar-border-radius:       $border-radius-large;

$navbar-default-bg:          saturate(adjust-hue(tint($brand-primary, 91%), -18), 2%);

//// Navbar links
$navbar-default-link-color:           $brand-primary;
$navbar-default-link-hover-color:     $brand-secondary;
$navbar-default-link-hover-bg:        transparent;
$navbar-default-link-active-color:    $brand-secondary;
$navbar-default-link-active-bg:       transparent;
$navbar-default-link-disabled-color:  #ccc;
$navbar-default-link-disabled-bg:     transparent;

//// Navbar nav carets
$navbar-default-caret-color:          $navbar-default-link-color;
$navbar-default-caret-hover-color:    $navbar-default-link-hover-color;
$navbar-default-caret-active-color:   $navbar-default-link-active-color;

//// Navbar brand label
$navbar-default-brand-color:          $navbar-default-link-color;
$navbar-default-brand-hover-color:    $navbar-default-link-hover-color;
$navbar-default-brand-hover-bg:       transparent;

//// Navbar toggle
$navbar-default-toggle-color:         $navbar-default-link-color;
$navbar-default-toggle-hover-color:   $navbar-default-link-hover-color;

//// Navbar form
$navbar-default-form-placeholder:     adjust-hue(tint($brand-primary, 60%), 2);
$navbar-default-form-icon:            desaturate(tint($brand-primary, 45%), 2%);
$navbar-default-form-border:          shade($navbar-default-bg, 3%);
//
//
//// Inverted navbar
//// Reset inverted navbar basics
$navbar-inverse-divider:              darken($brand-primary, 3%);
//
//// Reset inverted navbar basics
$navbar-inverse-color:                $inverse;
$navbar-inverse-bg:                   $brand-primary;
$navbar-inverse-border:               darken($navbar-inverse-bg, 10%);
//
//// Inverted navbar links
$navbar-inverse-link-color:           $inverse;
$navbar-inverse-link-hover-color:     $brand-secondary;
$navbar-inverse-link-hover-bg:        transparent;
$navbar-inverse-link-active-color:    $navbar-inverse-link-color;
$navbar-inverse-link-active-bg:       $brand-secondary;
$navbar-inverse-link-disabled-color:  #444;
$navbar-inverse-link-disabled-bg:     transparent;
//
//// Navbar nav carets
$navbar-inverse-caret-color:          lighten(desaturate($brand-primary, 7%), 9%);
$navbar-inverse-caret-hover-color:    $navbar-inverse-link-hover-color;
$navbar-inverse-caret-active-color:   $navbar-inverse-link-active-color;
//
//// Inverted navbar brand label
$navbar-inverse-brand-color:          $navbar-inverse-link-color;
$navbar-inverse-brand-hover-color:    $navbar-inverse-link-hover-color;
$navbar-inverse-brand-hover-bg:       transparent;
//
//// Inverted navbar toggle
$navbar-inverse-toggle-color:         $navbar-inverse-link-color;
$navbar-inverse-toggle-hover-color:   $navbar-inverse-link-hover-color;
//
//// Navbar form
$navbar-inverse-form-bg:              darken($brand-primary, 6%);
$navbar-inverse-form-placeholder:     desaturate(lighten($brand-primary, 13%), 7%);
$navbar-inverse-form-icon:            desaturate(lighten($brand-primary, 13%), 6%);
$navbar-inverse-form-border:          $navbar-inverse-divider;
//
//// Navbar dropdowns
$navbar-inverse-dropdown-bg:                $navbar-inverse-bg;
$navbar-inverse-dropdown-link-color:        mix($navbar-inverse-bg, $navbar-inverse-color, 15%);
$navbar-inverse-dropdown-link-hover-color:  $inverse;
$navbar-inverse-dropdown-link-hover-bg:     $brand-secondary;
//
//
////== Breadcrumbs
////
////##
//
//$breadcrubms-bg:           mix($brand-primary, white, 6%);
//$breadcrubms-text-color:   $gray-light;
//
//
////== Dropdowns
////
////## Dropdown menu container and contents.
//
//
//$zindex-dropdown:                1000;
//$dropdown-border-radius:         $border-radius-base;
//
////** Background for the dropdown menu.
//$dropdown-bg:                    desaturate(lighten($brand-primary, 67%), 20%);
//
////** Dropdown link text color.
//$dropdown-link-color:            mix(darken($brand-primary, 5%), $inverse, 75%);
////** Hover color for dropdown links.
//$dropdown-link-hover-color:      darken($dropdown-link-color, 5%);
////** Hover background for dropdown links.
//$dropdown-link-hover-bg:         fade(desaturate(lighten($brand-primary, 52%), 21%), 50%);
//
////** Active dropdown menu item text color.
//$dropdown-link-active-color:     $inverse;
////** Active dropdown menu item background color.
//$dropdown-link-active-bg:        $brand-secondary;
//
////** Disabled dropdown menu item background color.
//$dropdown-link-disabled-color:   $gray-light;
//
////** Divider color for between dropdown items.
//$dropdown-divider-bg:            fade($dropdown-link-hover-bg, 50%);
//
////** Text color for headers within dropdown menus.
//$dropdown-header-color:          fade($brand-primary, 60%);
//
//
//// Inverted dropdown
////
//
//$dropdown-inverse-bg:                    $brand-primary;
//
//
////** Dropdown link text color.
//$dropdown-inverse-link-color:            fade($inverse, 85%);
////** Hover color for dropdown links.
//$dropdown-inverse-link-hover-color:      fade($inverse, 85%);
////** Hover background for dropdown links.
//$dropdown-inverse-link-hover-bg:         fade(darken($brand-primary, 5%), 50%);
//
////** Active dropdown menu item text color.
//$dropdown-inverse-link-active-color:     fade($inverse, 85%);
////** Active dropdown menu item background color.
//$dropdown-inverse-link-active-bg:        $brand-secondary;
//
////** Disabled dropdown menu item background color.
//$dropdown-inverse-link-disabled-color:   fade($dropdown-inverse-link-color, 50%);
//
////** Divider color for between dropdown items.
//$dropdown-inverse-divider-bg:            $dropdown-inverse-link-hover-bg;
//
////** Text color for headers within dropdown menus.
//$dropdown-inverse-header-color:          fade($inverse, 40%);
//
//
////== Iconbar
////
////##
//
//$iconbar-background:         mix($brand-primary, black, 85%);
//$iconbar-color:              $inverse;
//$iconbar-hover-color:        $brand-secondary;
//
//
////== Progress bars
////
////##
//
//$progress-height:            12px;
//
//
////== Slider
////
////##
//
//$slider-height:              12px;
//$slider-value-font-size:     floor(($component-font-size-base * 0.867)); // ~13px;
//
//$slider-handle-bg:           mix($brand-secondary, black, 85%);
//$slider-handle-hover-bg:     mix($brand-secondary, white, 80%);
//$slider-handle-active-bg:    mix($brand-secondary, black, 85%);
//
//$slider-range-bg:            $brand-secondary;
//
//$slider-segment-bg:          mix(desaturate($brand-primary, 15%), white, 20%);
//
//
////== Dialog
////
////##
//
//$dialog-font-size:           floor(($component-font-size-base * 1.067)); // ~16px
//$dialog-padding:             18px 0 20px;
//$dialog-default-bg:          $brand-primary;
//$dialog-success-bg:          $brand-success;
//$dialog-danger-bg:           $brand-danger;
//$dialog-warning-bg:          $brand-warning;
//$dialog-info-bg:             $brand-info;
//
//
////== Alerts
////
////##
//
//$alert-bg:                   mix($brand-primary, white, 3%);
//$alert-border:               mix($brand-primary, white, 10%);
//$alert-success-bg:           mix($brand-success, $alert-bg, 5%);
//$alert-success-border:       mix($brand-success, $alert-border, 5%);
//$alert-success-link-color:   $link-color;
//$alert-info-bg:              mix($brand-info, $alert-bg, 1%);
//$alert-info-border:          mix($brand-info, $alert-border, 1%);
//$alert-info-link-color:      $brand-info;
//$alert-warning-bg:           mix($brand-warning, $alert-bg, 3%);
//$alert-warning-border:       mix($brand-warning, $alert-border, 3%);
//$alert-warning-link-color:   $brand-warning;
//$alert-danger-bg:            mix($brand-danger, $alert-bg, 2.5%);
//$alert-danger-border:        mix($brand-danger, $alert-border, 2.5%);
//$alert-danger-link-color:    $brand-danger;
//
//
////== Switch
////
////##
//
//$switch-name:                bootstrap-switch;
//$switch-border-radius:       30px;
//$switch-width:               80px;
//$switch-height:              29px;
//
//
////== Modals
//
//##

//** Padding applied to the modal body
$modal-body-padding:          20px 24px 20px;

//** Padding applied to the modal footer
$modal-footer-padding:        19px 22px 20px;

//** Padding applied to the modal title
$modal-title-padding:         17px 19px 15px 24px;
//** Modal title line-height
$modal-title-line-height:     30px;

//** Background color of modal content area
$modal-content-bg:            $inverse;
////** Modal content border color
$modal-content-border-color:  mix($brand-primary, white, 13%);
////** Modal content border radius
//$modal-content-border-radius: 7px;
//
////** Modal backdrop background color
$modal-backdrop-bg:           mix($brand-primary, black, 70%);
//
////** Modal header border color
$modal-header-border-color:   $modal-content-border-color;

//** Modal footer border color
$modal-footer-border-color:   $modal-header-border-color;
//** Modal footer background color
// $modal-footer-bg:             fade(adjust-hue(desaturate(lighten($brand-primary, 65%), 14%), -18), 50%);

$modal-lg:                    900px;
$modal-md:                    624px;
$modal-sm:                    300px;

$modal-backdrop-opacity:      .95;
$zindex-modal-background:     1040;
$zindex-modal:                1050;
//
//
////== Carousel
////
////##
//
//$carousel-background:           mix($brand-primary, black, 70%);
//$carousel-controls-background:  $midnight-blue;
//$carousel-border-radius:        $border-radius-large;
//
//
////== Video player
////
////##
//
//$vplayer-border-radius:               $border-radius-large;
//$vplayer-fullscreen-bg:               #000;
//$vplayer-fullscreen-zindex:           10000;
//
//$vplayer-control-bar-color:           $inverse;
//$vplayer-control-bar-bg:              $midnight-blue;
//
//$vplayer-preloader-primary-bg:        #e74c3c;
//$vplayer-preloader-secondary-bg:      #ebedee;
//
//$vplayer-text-track-bg:               rgba(0,0,0,.5);
//
//$vplaver-play-control-color:          $brand-secondary;
//$vplaver-play-control-hover-color:    mix($brand-secondary, black, 85%);
//
//$vplaver-second-controls-color:       desaturate(lighten($midnight-blue, 12%), 6%);
//$vplaver-second-controls-hover-color: desaturate(lighten($midnight-blue, 20%), 6%);
//
//$vplaver-progress-bg:                 mix($brand-primary, $inverse, 93%);
//$vplaver-play-progress-bg:            $brand-secondary;
//$vplaver-load-progress-bg:            mix($brand-primary, $inverse, 20%);
//
//$vplayer-seek-handle-bg:              mix($brand-secondary, black, 85%);
//$vplayer-seek-handle-hover-bg:        mix($brand-secondary, black, 75%);
//$vplayer-seek-handle-active-bg:       mix($brand-secondary, black, 65%);
//
//$vplayer-time-divider-color:          mix($brand-primary, $inverse, 80%);
//$vplayer-duration-color:              mix($brand-primary, $inverse, 80%);
//
//
////== Labels
////
////##
//
//$label-link-hover-color:      $inverse;
//
//$label-default-bg:            mix($inverse, $brand-primary, 90%);
//$label-default-bg-hover:      mix($inverse, $brand-primary, 85%);
//$label-default-text-color:    mix($brand-primary, $inverse, 65%);
//
//$label-inverse-bg:            mix($inverse, $brand-primary, 10%);
//$label-inverse-bg-hover:      mix($brand-primary, $inverse, 45%);
//
//$label-primary-bg:            $brand-secondary;
//$label-primary-bg-hover:      mix($brand-secondary, black, 85%);
//
//$label-success-bg:            $brand-success;
//$label-success-bg-hover:      mix($brand-success, black, 85%);
//
//$label-info-bg:               $brand-info;
//$label-info-bg-hover:         mix($brand-info, black, 85%);
//
//$label-warning-bg:            $brand-warning;
//$label-warning-bg-hover:      mix($brand-warning, black, 85%);
//
//$label-important-bg:          $brand-danger;
//$label-important-bg-hover:    mix($brand-danger, black, 85%);
//
//
////== Panels
////
////##
//
//$panel-bg:                    $inverse;
//$panel-border-radius:         $border-radius-large;
//$panel-inner-border:          $gray-light;
//$panel-footer-bg:             $gray-light;
//
//$panel-default-text:          $inverse;
//$panel-default-border:        $gray-light;
//$panel-default-heading-bg:    $gray-light;
//
//$panel-primary-text:          $inverse;
//$panel-primary-border:        $brand-primary;
//$panel-primary-heading-bg:    $brand-primary;
//
//$panel-success-text:          $link-color;
//$panel-success-border:        mix($brand-success, $alert-border, 5%);
//$panel-success-heading-bg:    mix($brand-success, $alert-bg, 5%);
//
//$panel-info-text:             $brand-info;
//$panel-info-border:           mix($brand-info, $alert-border, 1%);
//$panel-info-heading-bg:       mix($brand-info, $alert-bg, 1%);
//
//$panel-warning-text:          $brand-warning;
//$panel-warning-border:        mix($brand-warning, $alert-border, 3%);
//$panel-warning-heading-bg:    mix($brand-warning, $alert-bg, 3%);
//
//$panel-danger-text:           $brand-danger;
//$panel-danger-border:         mix($brand-danger, $alert-border, 2.5%);
//$panel-danger-heading-bg:     mix($brand-danger, $alert-bg, 2.5%);
//
//
////== Todo list
////
////##
//
//$todo-bg:               $brand-primary;
//$todo-bg-active:        mix($brand-primary, black, 85%);
//$todo-search-bg:        $brand-secondary;
//$todo-search-color:     $brand-primary;
//$todo-color:            mix($brand-primary, $inverse, 66%);
//$todo-name-color:       $inverse;
//$todo-color-active:     $brand-secondary;
//$todo-border-radius:    $border-radius-large;
//
//
////== Thumbnails
////
////##
//
////** Padding around the thumbnail image
//$thumbnail-padding:           4px;
////** Thumbnail background color
//$thumbnail-bg:                $body-bg;
////** Thumbnail border color
//$thumbnail-border:            $gray-light;
////** Thumbnail border radius
//$thumbnail-border-radius:     $border-radius-large;
//
////** Custom text color for thumbnail captions
//$thumbnail-caption-color:     $text-color;
////** Padding around the thumbnail caption
//$thumbnail-caption-padding:   9px;
//
//
////== Todo list
////
////##
//
//$tiles-bg:                   mix($brand-primary, $inverse, 8%);
//$tiles-border-radius:        $border-radius-large;
//
//
////== Badges
////
////##
//
//$badge-color:                 $inverse;
//$badge-link-hover-color:      $inverse;
//$badge-bg:                    mix($brand-primary, white, 19%);
//
//$badge-active-color:          mix($brand-primary, white, 50%);
//$badge-active-bg:             $inverse;
//
//$badge-font-weight:           normal;
//$badge-line-height:           1.615; // ~21px
//$badge-border-radius:         $border-radius-base;
//
////== Bottom menu
////
////##
//
//$bottom-menu-bg:                        mix($brand-primary, white, 10%);
//$bottom-menu-color:                     mix($brand-primary, white, 33%);
//$bottom-menu-link-color:                inherit;
//$bottom-menu-link-hover-color:          $brand-secondary;
//
//$bottom-menu-inverse-bg:                $brand-primary;
//$bottom-menu-inverse-color:             mix($brand-primary, white, 75%);
//$bottom-menu-inverse-link-color:        inherit;
//$bottom-menu-inverse-link-hover-color:  $brand-secondary;
//
//
////== Media queries breakpoints
////
////## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
$screen-xs-min:               480px;

// Small screen / tablet
$screen-sm-min:               768px;

// Medium screen / desktop
$screen-md-min:               992px;

// Large screen / wide desktop
$screen-lg-min:               1200px;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:               ($screen-sm-min - 1);
$screen-sm-max:               ($screen-md-min - 1);
$screen-md-max:               ($screen-lg-min - 1);


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:                12;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:           30px;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:       $screen-sm-min;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max:   ($grid-float-breakpoint - 1);


////== Form states and alerts
////
////## Define colors for form feedback states and, by default, alerts.
//
//$state-success-text:          $brand-success;
//$state-success-bg:            #dff0d8;
//$state-success-border:        darken(adjust-hue($state-success-bg, -10), 5%);
//
//$state-info-text:             $brand-info;
//$state-info-bg:               #d9edf7;
//$state-info-border:           darken(adjust-hue($state-info-bg, -10), 7%);
//
//$state-warning-text:          $brand-warning;
//$state-warning-bg:            #fcf8e3;
//$state-warning-border:        darken(adjust-hue($state-warning-bg, -10), 5%);
//
//$state-danger-text:           $brand-danger;
//$state-danger-bg:             #f2dede;
//$state-danger-border:         darken(adjust-hue($state-danger-bg, -10), 5%);
//
//
////== Tooltips
////
////##
//
////** Tooltip max width
//$tooltip-max-width:           183px;
////** Tooltip text color
//$tooltip-color:               $inverse;
////** Tooltip background color
//$tooltip-bg:                  $brand-primary;
//$tooltip-opacity:             1;
////** Tooltip zIndex
//$zindex-tooltip:              1070;
//
////** Tooltip inverse text color
//$tooltip-inverse-color:       $brand-primary;
////** Tooltip inverse background color
//$tooltip-inverse-bg:          mix($brand-primary, white, 9%);
//
////** Tooltip arrow width
//$tooltip-arrow-width:         9px;
////** Tooltip arrow color
//$tooltip-arrow-color:         $tooltip-bg;
////** Tooltip inverse arrow color
//$tooltip-inverse-arrow-color: $tooltip-inverse-bg;
//
//
////== Popovers
////
////##
//
////** Popover body background color
//$popover-bg:                          mix($brand-primary, white, 9%);
////** Popover maximum width
//$popover-max-width:                   276px;
////** Popover z-index
//$zindex-popover:                      1060;
//
////** Popover title background color
//$popover-title-bg:                    mix($brand-primary, white, 33%);
//
////** Popover arrow width
//$popover-arrow-width:                 9px;
////** Popover arrow color
//$popover-arrow-color:                 $popover-bg;
//
////** Popover outer arrow width
//$popover-arrow-outer-width:           ($popover-arrow-width + 1);
////** Popover outer arrow color
//$popover-arrow-outer-color:           transparent;
//
//
////== Code
////
////##
//
//$code-color:                  #c7254e;
//$code-bg:                     #f9f2f4;
//
//$kbd-color:                   $inverse;
//$kbd-bg:                      $brand-primary;
//
//$pre-bg:                      $inverse;
//$pre-color:                   inherit;
//$pre-border-color:            mix($brand-primary, $inverse, 12%);
//$pre-scrollable-max-height:   340px;
//$pre-border-radius:           $border-radius-large;
//
//
////== Type
////
////##
//
////** Text muted color
//$text-muted:                  $gray-light;
////** Abbreviations and acronyms border color
//$abbr-border-color:           $gray-light;
////** Headings small color
//$headings-small-color:        mix($brand-primary, $inverse, 12%);
////** Blockquote small color
//$blockquote-small-color:      inherit;
////** Blockquote border color
//$blockquote-border-color:     mix($brand-primary, $inverse, 12%);
////** Page header border color
//$page-header-border-color:    mix($brand-primary, $inverse, 12%);
////** Width of horizontal description list titles
//$dl-horizontal-offset:        $component-offset-horizontal;
//
//
////== Miscellaneous
////
////##
//
////** Hr border color
//$hr-border:                   mix($brand-primary, $inverse, 63%);
//
////** Horizontal forms & lists
//$component-offset-horizontal: 180px;
