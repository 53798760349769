/* Extra styles for AudienceReport Controlpanel */
@import "variables";
@import "variables_theme";
@import "flat-ui-pro";
@import "flaticons";
@import "mixins";
//$import "spaces";
//$import "spinner-preloader";
@import url(http://fonts.googleapis.com/css?family=Roboto+Slab|Open+Sans:400italic,700italic,400,700);
@import url(https://fonts.googleapis.com/css?family=Titillium+Web:200,400,600);

/* General styles */
html {
  overflow-y: scroll;
  font-family: "Titillium Web", Helvetica, Arial, sans-serif !important;
}

body {
  font-weight: 400;
  background: #ffffff;
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $text-color;
  background-color: $body-bg;
}

// Headings
// -------------------------

h1, h2, h3, h4, h5, h6 {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1, h2, h3 {
  margin-top: $line-height-computed;
  margin-bottom: ($line-height-computed / 2);
}

h4, h5, h6 {
  margin-top: ($line-height-computed / 2);
  margin-bottom: ($line-height-computed / 2);
}

h1 {
  font-size: $font-size-h1;
}

// ~62px
h2 {
  font-size: $font-size-h2;
}

// ~52px
h3 {
  font-size: $font-size-h3;
}

// ~40px
h4 {
  font-size: $font-size-h4;
}

// ~29px
h5 {
  font-size: $font-size-h5;
}

// ~28px
h6 {
  font-size: $font-size-h6;
}

// ~24px


.svg-content {
  svg {
    display: block;
  }
}

///* ------- SHARED STYLES FOR USE WITH :EMBED ------- */
//
.transition-all {
  transition: all 0.25s ease;
}

.transition-background {
  transition: background 0.25s ease;
}

.rounded-all {
  border-radius: 2px;
}

.flaticon {
  font-family: 'Flaticons Stroke';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

///* ------- HEADER ------- */
///* New items */
.navbar-new,
.navbar-unread {
  background: $brand-success;
}

///* Headings */
h1,
h2,
h3 {
  margin-top: $line-height-computed * 1.3;
  margin-bottom: ($line-height-computed * 1.3);
}

///* Logo */
.navbar-brand {
  width: 81px;
  padding-left: 30px;
  padding-right: 30px;
  height: $navbar-height-large;
  background: $navbar-inverse-form-bg;

  .has-max-width & {
    margin-left: 0 !important;
  }

  .fill {
    fill: #ffffff;
    @extend .transition-all;
  }

  &:hover {
    background: shade($navbar-inverse-form-bg, 30%) !important;

    .fill {
      fill: $brand-secondary;
    }
  }
}

/* Navbar */
#navbar-header {
  .flaticon {
    display: inline-block;
  }

  .navbar-icons {
    margin-right: -20px !important;
  }

  .avatar-wrap {
    padding: 0;
    height: $navbar-height-large;
    width: 80px;
    margin-left: 15px;

    .avatar {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -20px 0 0 -20px;
      border: 2px solid shade($brand-primary, 20%);
      @extend .transition-all;
    }
  }

  .dropdown-menu {
    a {
      cursor: pointer;
    }

    .divider {
      background-color: shade($brand-primary, 20%);
      border-bottom: 1px solid shade($brand-primary, 20%);
    }
  }

  .avatar-wrap:hover,
  li.open .avatar-wrap {
    .avatar {
      border-color: $brand-secondary;
    }
  }
}

.dialog-enter {
  opacity: 0.01;
  transform: scale(1.1);
}

.dialog-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 300ms;
}

.dialog-exit {
  opacity: 1;
  transform: scale(1);
}

.dialog-exit-active {
  opacity: 0.01;
  transform: scale(1.1);
  transition: all 300ms;
}

.icon {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  top: 1px;
}

/* Project status labels */
.table-projects .status {
  text-transform: capitalize;

  .icon {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    top: 1px;
  }

  &.status-active {
    color: shade($brand-success, 10%);

    .icon:before {
      content: '\e57a';
    }
  }

  &.status-new {
    color: $brand-primary;

    .icon:before {
      content: '\e42c';
    }
  }

  &.status-dirty {
    color: shade($brand-warning, 10%);

    .icon:before {
      content: '\e4ee';
    }
  }

  &.status-deleted {
    color: shade($brand-danger, 10%);

    .icon:before {
      content: '\e480';
    }
  }

  &.status-archived {
    color: shade($gray-light, 10%);

    .icon:before {
      content: '\e3fc';
    }
  }
}

/* Dropdown menu */
.dropdown-menu {
  top: -5px;
  width: 230px;
  right: -115px;
  position: absolute;
  left: auto !important;
  margin: 0;

  li {
    position: relative;
    border-bottom: 1px dashed $table-border-color;

    &:last-child {
      border-bottom: none;
    }

    a {
      position: relative;
      font-weight: $headings-font-weight;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 12px 35px;

      &:after {
        @extend .transition-all;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        background: $gray-lighter;
        font-size: 0.75em;
        color: tint($text-color, 50%);
        font-weight: normal;
        padding: 15px;
        opacity: 0;
        visibility: hidden;
      }
    }

    &.company a:after {
      content: 'Hit enter to view company';
    }

    &.user a:after {
      content: 'Hit enter to edit this user';
    }

    &.project a:after {
      content: 'Hit enter to view more details';
    }

    /* Active */
    &.active {
      a {
        background: $gray-lighter;
        color: $text-color;

        &:after {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    /* Highlight */
    strong {
      background: fade($brand-warning, 30%);
      color: fade(shade($brand-warning, 50%), 80%);
    }
  }
}

//
/* Labels */
li:first-child,
li.company + li.user,
li.user + li.project {
  &:before {
    display: block;
    font-weight: $headings-font-weight;
    text-transform: uppercase;
    font-size: 0.8em;
    color: inherit;
    letter-spacing: 0.075em;
    color: tint($text-color, 50%);
    padding: 22px 40px 12px 40px;
    border-bottom: 1px dashed $table-border-color;
  }

  &:after {
    content: '';
    position: absolute;
    top: 27px;
    left: 20px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
  }
}

li.company:first-child {
  &:before {
    content: 'Companies';
  }

  &:after {
    background: tint($brand-primary, 20%);
  }
}

li.company + li.user,
li.user:first-child {
  &:before {
    content: 'Users';
  }

  &:after {
    background: tint($brand-secondary, 20%);
  }
}

li.user + li.project,
li.project:first-child {
  &:before {
    content: 'Projects';
  }

  &:after {
    background: tint($brand-success, 20%);
  }
}

//}

.navbar-nav {
  a {
    cursor: pointer;
  }

  .ver-divider {
    width: 2px;
    height: $navbar-height-base;
    background: rgba(0, 0, 0, 0.2);

    .navbar-lg & {
      height: $navbar-height-large;
    }
  }

  .active .caret {
    border-bottom-color: #ffffff !important;
    border-top-color: #ffffff !important;
  }
}

.navbar-inverse {
  .form-control {
    background: $navbar-inverse-form-bg !important;

    &:focus {
      /* 			border-color: transparent !important; */
      background: shade($navbar-inverse-form-bg, 30%) !important;
    }
  }
}

.body-wrap {
  background: #ffffff;
  padding: 120px 0 50px 0;
}

///* ------- FOOTER ------- */

footer {
  padding: 50px 0;
  font-size: 0.8em;
  color: tint($brand-primary, 50%);
}

/* Highcharts styles */

.colorSeries1 {
  color: $brand-secondary;
}

.colorSeries2 {
  color: $brand-primary;
}

.colorSeries3 {
  color: $brand-success;
}

.colorGridline {
  color: $table-border-color;
}

.colorText {
  color: $text-color;
}

.colorTextLight {
  color: tint($text-color, 50%);
}

.highcharts-tooltip > span {
  box-shadow: rgba(0, 0, 0, 0.25) 0 0 10px;
  //.box-shadow(0 0 10px rgba(0,0,0,0.25));
  border: 1px solid $table-border-color;
  background: #ffffff;
  width: 250px;
}

.highcharts-tooltip .header {
  padding: 11px 15px 9px 15px;
  background: $gray-lighter;
  margin: 0;
  color: $brand-primary;
}

.highcharts-tooltip table td {
  white-space: nowrap;
  padding: 11px 15px;
  border-top: 1px solid $table-border-color;
  font-size: $font-size-h5;
  color: tint($brand-primary, 50%);
}

.highcharts-tooltip-inner {
  width: 100%;
}

.highcharts-tooltip table tr:first-child td {
  border: none;
}

.highcharts-tooltip table .td-left {
  //padding-right: 40px;
}

.highcharts-tooltip table td.td-right {
  text-align: right;
  font-weight: $headings-font-weight;
  color: $brand-primary;
}

.graph-wrap {
  padding: 30px 35px;
  margin-bottom: 30px;

  & {
    @extend .rounded-all;
  }

  border: 1px solid $table-border-color;
}

.hc-wrap {
  padding-bottom: 5px;

  h3 {
    margin-top: 0;
  }
}

.hc-kpi .hc {
  height: 500px;
}

.number-wrap {
  small {
    display: block;
    font-size: 0.75em;
  }

  .icon {
    position: relative;
    float: left;
    margin: 3px 25px 0 -5px;
    width: 60px;
    height: 60px;
    background: tint($brand-secondary, 90%);
    border-radius: 30px;
  }

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -12px 0 0 -12px;
    color: $brand-secondary;
  }
}

///* Tooltips */

.tooltip-inner,
.dropdown-menu {
  box-shadow: rgba(0, 0, 0, 0.25) 0 0 10px;
}

.tooltip-inner {
  width: auto !important;
  max-width: 200px !important;
  border-radius: 2px !important;
  padding: 10px 15px !important;
}

/* Dropdowns */
#move-account-selector {
  border-radius: 0 !important;
}

#move-account-selector .rbt-highlight-text {
  border-radius: 0 !important;
}

.kpi-inputs {
  position: relative;
  padding-right: 130px;

  .hasDatepicker {
    padding-left: 0;
  }

  .kpi-refresh {
    position: absolute;
    top: 0;
    right: 0;

    label {
      opacity: 0;
      display: block;
    }

    .btn {
      width: 100px;
    }
  }
}

.raw-logs-modal label, .raw-logs-modal p {
  line-height: 1.5;
}

.form-group,
.select {
  &.full-width {
    max-width: 100% !important;
    display: block !important;
  }
}

/* Select */
select {
  min-height: 35px;
  padding: 5px 10px !important;
}

/* Multiselect */
.select2-container-multi {
  height: auto !important;
  padding: 8px 8px 5px 8px !important;
}

.select2-choices {
  margin: -2px 0;
}

.select2-search-choice {
  background: $brand-primary;

  &:hover {
    background: $brand-danger;
  }
}

.dropdown-menu {
  padding: 0 !important;

  li a {
    padding: 12px 20px !important;
  }

  li dt {
    font-weight: $headings-font-weight;
    margin-bottom: 10px;
  }

  li.danger a:hover {
    background: tint($brand-danger, 90%) !important;
  }

  [class^='fui-'] {
    margin: -1px 15px 0 0 !important;
    top: auto !important;
    vertical-align: middle !important;
    font-size: 16px !important;
  }

  li.danger {
    a {
      color: $brand-danger;
    }
  }
}

.nomargin {
  margin: 0 !important;
}

.open > .dropdown-menu {
  margin-top: 10px !important;
}

/* Container */
.container-fluid.has-max-width {
  max-width: 1600px;
  padding-left: 30px;
  padding-right: 30px;
}

/* ------- CONTENT ------- */

/* Lists */

.details-list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 2px;
  }
}

.color {
  color: shade($brand-secondary, 10%);
}

.box {
  padding: 15px 20px;
  background: rgba(255, 255, 255, 0.5);
  font-size: $component-font-size-base;
  font-weight: $headings-font-weight;

  & {
    @extend .rounded-all;
  }

  &.pull-left {
    margin-right: 10px;
  }

  [class^='fui-'] {
    float: left;
    margin: 6px 8px 0 0;
  }

  h5 {
    text-transform: uppercase;
    font-size: 0.75em;
    margin: 0 0 3px 0;
    letter-spacing: 0.075em;
    color: tint($text-color, 50%);
  }
}

.has-border {
  position: relative;

  &:after {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: $table-border-color;
  }
}

/* Fluid grid */

.fluid-grid {
  width: 102%;
  margin: -1% -1% 0 -1%;
}

//.build-fluid-item($items: 2) {
//  $spaces: $items * 2;
//  $margin: 1%;
//  $total: 100% - ($spaces * $margin);
//  $width: $total / $items;
//  width: $width;
//  margin: $margin;
//  padding-bottom: $width;
//}

.fluid-item {
  position: relative;
  top: 0;
  overflow: hidden;
  float: left;
  width: 100%;
  height: auto;
  text-decoration: none;

  & {
    @extend .transition-all, .rounded-all;
  }

  .fluid-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  img {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  $items: 3;
  .fluid-item {
      $spaces: $items * 2;
      $margin: 1%;
      $total: 100% - ($spaces * $margin);
      $width: $total / $items;
      width: $width;
      margin: $margin;
      padding-bottom: $width;
  }
}

//$media screen and (min-width: 1000px) {
@media (min-width: 1000px) {
  $items: 4;
  .fluid-item {
    $spaces: $items * 2;
    $margin: 1%;
    $total: 100% - ($spaces * $margin);
    $width: $total / $items;
    width: $width;
    margin: $margin;
    padding-bottom: $width;
  }
}

@media (min-width: 1200px) {
  $items: 5;
  .fluid-item {
    $spaces: $items * 2;
    $margin: 1%;
    $total: 100% - ($spaces * $margin);
    $width: $total / $items;
    width: $width;
    margin: $margin;
    padding-bottom: $width;
  }
}

@media (min-width: 1500px) {
  $items: 6;
  .fluid-item {
    $spaces: $items * 2;
    $margin: 1%;
    $total: 100% - ($spaces * $margin);
    $width: $total / $items;
    width: $width;
    margin: $margin;
    padding-bottom: $width;
  }
}

/* Company grid */
#company-grid {
  .fluid-item {
    cursor: pointer;

    .fluid-content {
      background: $gray-lighter;
      @extend .transition-all;
    }

    .btn {
      position: absolute;
      top: -50px;
      right: -50px;
      z-index: 2;
      opacity: 0;

      transition: all 0.25ms cubic-bezier(0.72, 0, 0.4, 1);
    }
  }

  .no-touch & .fluid-item:hover,
  .fluid-item.hover {
    .fluid-content {
      background: tint($brand-secondary, 80%);
    }

    .btn {
      top: 0;
      right: 0;
      opacity: 1;
    }
  }

  .fluid-content {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    top: 0;
    left: 0;
    padding-top: 20%;
    z-index: 1;
  }

  .avatar {
    display: block;
    margin: 0 auto;
    border: 4px solid rgba(255, 255, 255, 0.3);
  }

  .title {
    margin: 20px 0 5px 0;
    font-weight: normal;
  }

  .cid {
    font-size: 0.75em;
    color: tint($brand-primary, 50%);
  }
}

/* Page transitions */
.container {
  position: relative;
}

.page {
  position: absolute;
  left: 15px;
  right: 15px;
}

.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

small {
  color: tint($brand-primary, 50%) !important;
}

.badge {
  border-radius: 20px;
  background: $brand-primary;

  &.badge-lg {
    font-size: $font-size-h4;
    padding: 3px 12px;
  }
}

/* Colors */

.color {
  &.success {
    color: $brand-success;
  }

  &.warning {
    color: $brand-warning;
  }

  &.info {
    color: $brand-info;
  }

  &.danger {
    color: $brand-danger;
  }
}

/* Tables */

.table {
  font-size: $component-font-size-base;

  th {
    background: $gray-lighter-transparent;
    border: none !important;

    &:first-child {
      border-radius: 2px 0 0 2px;
    }

    &:last-child {
      border-radius: 0 2px 2px 0;
    }
  }

  &.table-condensed {
    font-size: $font-size-h5;
  }

  &:not(.table-condensed) th {
    height: 50px;
  }

  td,
  a,
  code {
    color: $text-color;
  }

  .cb-cell {
    padding: 0 10px !important;
    width: 40px !important;
  }

  small {
    margin-left: 5px;
  }

  .label {
    margin-right: 15px;
    font-weight: $headings-font-weight;
    font-size: $component-font-size-base * 0.8333;
  }

  tbody tr:first-child td {
    border: none !important;
  }

  a.title:hover {
    color: $brand-secondary;
  }

  td,
  th {
    & {
      @extend .transition-background;
    }
  }

  code {
    background: none;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.05);
    padding: 4px 9px;
  }

  .icon-actions {
    padding: 0 0 0 10px;
    width: 160px;

    &.has-one-icon {
      width: 60px;
    }

    &.has-two-icons {
      width: 110px;
    }

    &.has-three-icons {
      width: 160px;
    }
  }

  &.table-hover tr:hover td {
    background: tint($brand-secondary, 90%);
  }

  .icon-action {
    position: relative;
    width: 50px;
    height: 50px;
    float: left;
    color: tint($brand-primary, 70%);
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -9px 0 0 -8px;
    }

    &:hover {
      color: $brand-secondary;
    }

    .btn-group > .btn {
      float: none;
    }
  }

  /* Companies & Users table */
  &.table-companies,
  &.table.table-users {
    td {
      padding-left: 24px;
      padding-right: 24px;
    }

    td:first-child {
      width: 32px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  /* Exports table */
  &.table-exports {
    tr:not(:first-child) {
      cursor: pointer;
    }

    tr.disabled {
      cursor: default;

      td,
      small {
        color: tint($brand-primary, 70%) !important;
      }

      &:hover td {
        background: none !important;
      }
    }
  }

  /* Products table */
  &.table-projects {
    td.projectName {
      text-overflow: ellipsis;
      max-width: 450px;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}


///* Checkboxes */
.pretty-checkbox {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide checkbox element */
  input {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  /* Default */
  i {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin: -10px 0 0 -10px;
    z-index: 1;
    background: #ffffff;
    border: 2px solid tint($brand-primary, 70%);
    border-radius: $border-radius-large;
    color: $brand-primary;
    @extend .transition-all;
    overflow: hidden;

    &:after,
    &:before {
      @extend .flaticon;
      font-size: 13px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -6px;
      opacity: 0;
      transition: all 0.2s cubic-bezier(0.74, -0.41, 0.4, 0.98);
      //.transition(all 0.2s cubic-bezier(0.74,-0.41,0.4,0.98));
    }

    &:after {
      content: '\e5a2';
    }

    &:before {
      content: '\e5a1';
    }
  }

  /* Hover */
  &:hover i {
    color: $brand-secondary;
    border-color: $brand-secondary;
  }

  /* Checked */
  input[type='checkbox']:checked ~ i {
    &:after {
      opacity: 1;
      top: 2px;
    }
  }

  /* Indeterminate */
  input[type='checkbox']:indeterminate ~ i {
    &:before {
      opacity: 1;
      top: 2px;
    }
  }

  /* When inside a label - align checkbox with text */
  label & {
    margin: -2px 10px 0 0;
  }

  /* When inside a table */
  .table & {
    width: 40px;
    height: 40px;
  }
}

/* Alerts */

//.built-alert($color) {
//  background: tint($color, 80%);
//  color: shade($color, 50%);
//}

.alert {
  padding: 12px 45px 12px 15px;
  border: none;
  border-radius: 2px;

  /* Variations */
  //&.alert-success {
  //  .built-alert($brand-success);
  //}
  //&.alert-info {
  //  .built-alert($brand-info);
  //}
  //&.alert-warning {
  //  .built-alert($brand-warning);
  //}
  //&.alert-danger {
  //  .built-alert($brand-danger);
  //}

  .close {
    font-size: 24px;
    font-weight: normal;
    color: inherit;
    right: -25px;
  }
}

/* Inputs */
.form-control,
.input-group-btn .btn {
  border-color: $table-border-color;
  line-height: 20px;
  border-width: 1px;
}

.input-group {
  .hasDatepicker {
    border-left: none;
    position: relative;
  }
}

.bulk-action {
  margin-left: 20px;

  .btn {
    margin: 0 5px;
  }

  &.disabled {
    .btn[disabled] {
      background: none;
      color: $gray-light;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;

        & {
          @extend .rounded-all, .transition-all;
        }

        width: 100%;
        height: 100%;
        border: 1px dashed $gray-light;
      }
    }
  }
}

.color-wrap {
  position: relative;
}

.preview-color {
  position: absolute;
  bottom: 15px;
  right: 20px;
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  border-radius: 6px;
  @extend .transition-all;
}

.form-group {
  .fui-search {
    font-size: 13px !important;
  }
}

.thumbnail {
  border: none;
  width: 100%;
  height: 105px;
  display: block;

  > img {
    max-height: 100%;
  }
}

.fileinput {
  position: relative;
  margin: 0 !important;

  &:hover .fileinput-buttons {
    opacity: 1;
  }

  .thumbnail {
    border: none;
    width: 100%;
    height: 105px;
    display: block;
  }

  .fileinput-preview {
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .fileinput-buttons {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -30px 0 0 -30px;
    text-align: center;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 50%;
    @extend .transition-all;

    &.fileinput-upload {
      opacity: 1;
    }

    .btn.btn-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
    }

    .flaticon:before {
      cursor: pointer;
      display: inline-block;
    }

    input {
      cursor: pointer;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      font-size: 0;
    }
  }

  .trash-1 {
    color: $brand-danger;
  }
}

///* Helpful classes */
.table.table-vcenter {
  td,
  th {
    vertical-align: middle !important;
  }

  .trackpoint-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 260px;
    display: inline-block;
  }
}

.table.table-borderless {
  th,
  td {
    border: none;
  }
}

.table {
  td,
  th {
    border-width: 1px !important;
  }
}

.pull-up {
  margin-top: -5px !important;
}

.capitalize {
  text-transform: capitalize;
}

//$media screen and (min-width: $screen-md-min) {
//  .padding-right {
//    padding-right: 20px;
//  }
//
//  .padding-left {
//    padding-left: 20px;
//  }
//}

/* Avatar */
.avatar {
  border-radius: 50%;
  display: block;
  overflow: hidden;

  /* Constrain image */
  img {
    width: 100%;
    height: 102%;
  }

  /* Default size */
  width: 30px;
  height: 30px;

  /* Small */
  &.sm {
    width: 20px;
    height: 20px;
  }

  /* Largel */
  &.md {
    width: 40px;
    height: 40px;
  }

  /* Largel */
  &.lg {
    width: 80px;
    height: 80px;
  }

  &.gravatar {
  }
}

/* Gender icons */
.gender-icon > svg {
  width: 30px;
  height: 30px;
  margin: 5px;
  display: none;
}

.gender-icon > svg.gender-icon-male {
  display: block;

  .fill {
    fill: $gray-light;
  }
}

.gender-icon.male > svg.gender-icon-male {
  .fill {
    fill: #2379ec;
  }
}

.gender-icon.female {
  svg.gender-icon-female {
    display: block;

    .fill {
      fill: #a771b2;
    }
  }

  svg.gender-icon-male {
    display: none;
  }
}

.icon24 {
  &:before {
    font-size: 24px !important;
  }
}

/* Buttons */
.btn {
  /*
      &:not(.btn-icon) [class^="fui-"] {
          margin:0 7px 0 0 !important;
      }
  */

  &.btn-icon {
    > [class^='fui-'] {
      margin: 0 !important;
    }

    &.btn-link:hover {
      text-decoration: none !important;
    }
  }

  &.btn-link-neutral {
    color: $brand-primary;
    opacity: 0.2;

    &:hover {
      opacity: 0.5;
    }
  }

  &.btn-sm.btn-icon,
  .btn-group-sm &.btn-icon {
    line-height: 1.2 !important;
  }
}

.btn-group {
  .btn {
    border-radius: 2px !important;
  }

  > .btn + .dropdown-toggle {
    border: none;
    margin-left: 2px;
  }
}

.margin-right {
  margin-right: 20px;
}

.margin-left {
  margin-left: 20px;
}

/* Modals */

.modal {
  /* Header */
  .modal-header {
    border-width: 1px;

    /* Close */
    .close {
      font-size: 28px;
      font-weight: normal;
      margin-top: -4px;
    }
  }

  /* Title */
  .modal-title {
    font-size: $font-size-h3;

    small {
      margin-left: 10px;
    }
  }

  /* Section title */
  .modal-section-title {
    margin: 0 0 25px 0;
    color: shade($brand-secondary, 10%);
  }

  .modal-content {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) -15px 15px 1px;
  }
}

.scroll-box {
  max-height: 290px;
  overflow-y: scroll;
  padding-right: 10px;
}

.modal-section-title {
  margin: 0 0 12px 0;
  color: shade($brand-secondary, 10%);
}

.modal {
  ::-webkit-scrollbar {
    width: 10px;
    border: none;
    cursor: pointer;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
    border: none;
    background: $gray-lighter-transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border: none;
    background: tint($brand-primary, 70%);
    transition: background 0.25s linear;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $brand-secondary;
  }
}

/* Fixes some weird modal scrollbar appearance issues */

body.modal-open,
.modal-open .navbar-fixed-top,
.modal-open .navbar-fixed-bottom {
  margin-right: 0;
}

.modal {
  overflow-y: auto;
}

/* Modal - Login as */

.login-as-item {
  position: relative;
  text-align: center;
  font-size: 14px;
  padding: 50px;
  color: tint($brand-primary, 50%);

  &:not(:last-child) {
    border-right: 1px solid $modal-header-border-color;
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(255, 255, 255, 0.01);
    @extend .transition-all;
  }

  .no-touch &:hover,
  &:active {
    a {
      background: fade($brand-secondary, 10%);
    }

    .login-as-item-mark {
      //.scale(1.2);
    }
  }

  .no-touch &.login-as-item-alpha:hover,
  &.login-as-item-alpha:active {
    a {
      background: fade($brand-danger, 10%);
    }
  }
}

.login-as-item-headline {
  color: $brand-primary !important;
  margin: 40px 0 5px 0;
}

.login-as-item-mark {
  position: relative;
  margin: 0 auto;
  width: 42px;
  height: 40px;
  @extend .transition-all;

  .fill {
    fill: $brand-secondary;
  }

  .login-as-item-alpha & {
    .fill {
      fill: $brand-danger;
    }

    &:after {
      content: 'ALPHA';
      position: absolute;
      top: -15px;
      right: -35px;
      padding: 0 6px;
      background: $modal-content-bg;
      font-size: 11px;
      font-weight: 600;
      color: $brand-danger;
      border: 1px solid $brand-danger;
      letter-spacing: 0.1em;

      & {
        @extend .rounded-all;
      }
    }
  }
}

/* Tabs */
.tab-content {
  border: none;
  border-radius: 0;
  padding: 0;
}

.nav-tabs {
  margin-top: 60px;
  margin-left: -30px;
  margin-right: -30px;
  padding-left: 30px;
  border-bottom: 1px solid $table-border-color;

  /* Badges */

  .badge {
    margin: 2px 0 0 10px;
    font-size: 0.8em;
    background: tint($text-color, 60%);
    color: $gray-lighter;
    @extend .transition-all;
  }

  > li {
    margin-right: 10px;

    /* All tabs */
    > a {
      color: tint($text-color, 50%);
      padding: 10px 25px !important;
      background: $gray-lighter;
      @extend .transition-all;

      &:hover {
        background: shade($gray-lighter, 2%);
        color: $text-color;

        .badge {
          background: $brand-primary;
          color: #ffffff;
        }
      }
    }

    /* Active tabs */
    &.active {
      > a,
      > a:hover {
        color: $text-color !important;
        background: none;
      }

      .badge {
        background: $brand-primary;
        color: #ffffff;
      }
    }

    &:not(.active) {
      border: 1px solid $gray-lighter;
      border-bottom: none;
    }
  }
}

/* Dividers */
.section-divider {
  width: 100%;
  height: 1px;
  background: $table-border-color;
  display: block;
  margin: 40px 0;
}

.empty-divider {
  width: 100%;
  height: 40px;

  &.small {
    height: 20px;
  }
}

.modal-divider {
  position: relative;

  &:after {
    content: '';
    width: 1px;
    height: 110%;
    position: absolute;
    top: -5%;
    right: -15px;
    background: $table-border-color;
  }
}

/* Breadcrumbs */
.page-breadcrumb {
  a {
    color: $brand-secondary;
    cursor: pointer;

    &:after {
      & {
        @extend .flaticon;
      }

      content: '\e5ab';
      color: tint($brand-primary, 70%);
      margin: 0 10px;
    }

    &.inner:after {
      margin: 0;
      content: '';
    }
  }
}

/* Login page */


/* centered columns styles */
.row-centered {
  text-align: center;
}

.col-centered {
  display: inline-block;
  float: none;
  /* reset the text-align */
  text-align: left;
  /* inline-block space fix */
  margin-right: -4px;
}

/* pagination */

.pagination {
  li > a {
    background: $brand-primary;
  }

  li > a:hover,
  li > a:focus {
    background: $brand-primary;
    color: $brand-secondary;
  }

  li.active > a,
  li.active > a:hover,
  li.active > a:focus {
    background: $brand-secondary;
    opacity: 1;
    border-color: $gray-lighter;
  }
}

/* react-datepicker */
.react-datepicker {

  font-size: 14px !important;
  border: 7px solid #16d6d8 !important;
  font-family: "Titillium Web", Helvetica, Arial, sans-serif !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker__triangle {
  border: none !important;
}

.react-datepicker__header {
  padding-bottom: 7px !important;
  background-color: #16d6d8 !important;
  border-bottom: none !important;
}
.react-datepicker__month {
  margin: 0.4em 1em !important;
}

.react-datepicker__day.react-datepicker__day--disabled {
  opacity: 0.5 !important;
}

.react-datepicker__day-names .react-datepicker__day-name{
  color: white !important;
}



.react-datepicker__day-name, .react-datepicker__day {
  width: 1.6em !important;
  line-height: 1.6em !important;
  margin: 0.166em !important;
  color: #264463 !important;
  padding: 3px 5px !important;
}

.react-datepicker__current-month {
  font-weight: normal !important;
  font-size: 1.2em !important;
  color: white !important;
}
.react-datepicker__navigation {
  top: 1em !important;
  line-height: 1.5em !important;
  border: 0.45em solid transparent !important;
}

.react-datepicker__navigation--previous {
  border-right-color: white !important;
  left: 1em !important;
}

.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
  color: white !important;
  background-color: #16d6d8 !important;
}

.react-datepicker__navigation--next {
  border-left-color: white !important;
  right: 1em !important;
}

/* util */
.pa-1 {
  padding: 16px;
}

.pa-2 {
  padding: 8px;
}

.pr-1 {
  padding-right: 16px;
}

.bg-red {
  background-color: #f14c4c;
}